<template>
  <v-container fluid>
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="374"
      color="#fff"
    >
      <img :src="require('@/assets/logo.svg')" alt="Logo" class="logo" />
      <v-card-title class="d-flex justify-center pa-0 mt-6">ファイルダウンロード</v-card-title>

      <v-card
          class="d-flex justify-center flex-column mx-auto my-0 flat"
          width="1200"
          color="#fff"
        >
        <v-list>
          <v-subheader>自動ダウンロードされない場合は下記をクリックしてください。</v-subheader>
          <v-list-item-group  v-if="fileList.length" color="primary">
            <v-list-item v-for="item in fileList" :key="item.attachmentId">
              <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group  v-if="!fileList.length" color="primary">
            <v-list-item>ファイルはありません</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
      <v-card-text class="d-flex justify-center flex-column">
        <a style="color:#000;" href="#" @click.prevent.stop="toLoginPage()">ログイン画面へ</a>
      </v-card-text>
    </v-card>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { saveAs } from 'file-saver';

export default {
  props: ['collisionId'],
  data() {
    return {
      fileList: [],
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // ローディング開始
    this.$root.loading = true;

    // メールアドレス認証
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";

    var data = {
      funcName: "fileDownload",
      collisionId: this.collisionId,
    }
    var url = "";
    var fileName = "";
    const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");
    await schoolAidHomePublic(data).then((ret) => {
      console.log(ret);
      url = ret.data.url;
      fileName = ret.data.fileName;
      var list = [{
        attachmentId: 0,
        url: ret.data.url,
        fileName: ret.data.fileName,
      }];
      this.fileList = list;
    }).catch(() => {
      this.$root.loading = false;
    });
    saveAs(url, fileName);

    // ローディング終了
    this.$root.loading = false;
  },
  methods: {
    // ログイン画面へ
    toLoginPage() {
      this.$router.push("/");
    },
    async onClickDwonload(item) {
      saveAs(item.url, item.fileName);
    },
  },
};
</script>

<style>
.logo {
  display: block;
  position: relative;
  overflow: hidden;
}
</style>