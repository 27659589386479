<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          生徒・保護者割当({{ groupName }})
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" justify="left">
        <v-card class="mx-auto text-left" color="white">
          課程・部・科・学年を指定し、検索した後に割当を行ってください。
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="white">
            <v-row>
              <v-col cols="2" justify="center">
                <v-select
                  label="課程"
                  :items="kateiMst"
                  @change="changeKBK"
                  item-text="name"
                  item-value="code"
                  v-model="selectedKatei"
                  outlined
                  dense
                  :disabled="isSearch"
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="2" justify="center">
                <v-select
                  label="部"
                  :items="buMst"
                  @change="changeKBK"
                  item-text="name"
                  item-value="code"
                  v-model="selectedBu"
                  outlined
                  dense
                  :disabled="isSearch"
                  hide-details="auto"
                  ></v-select>
              </v-col>
              <v-col cols="2" justify="center">
                <v-select
                  label="科"
                  :items="kaMst"
                  @change="changeKBK"
                  item-text="name"
                  item-value="code"
                  v-model="selectedKa"
                  outlined
                  dense
                  :disabled="isSearch"
                  hide-details="auto"
                  ></v-select>
              </v-col>
              <v-col cols="2" justify="center">
                <v-select
                  label="学年"
                  :items="gakunenMst"
                  @change="changeKBKGC"
                  item-text="name"
                  item-value="name"
                  v-model="selectedGakunen"
                  outlined
                  dense
                  :disabled="isSearch"
                  hide-details="auto"
                  ></v-select>
              </v-col>
              <v-col cols="2" justify="center">
                <v-btn color="grey" class="black--text" @click="onClear" :disabled="!activeClear">
                クリア
                </v-btn>
              </v-col>
              <v-col cols="2" justify="center">
                <v-btn color="accent" class="black--text" @click="onSearch" :disabled="!activeSearch">
                検索
                </v-btn>
              </v-col>
          </v-row>
        </v-card> 
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="userList"
          :items-per-page="30"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          :loading="isListLoding" loading-text="読込中"
          dense
        >
          <!-- 生徒所属チェックボックス -->
          <template #[`item.attachStudentFlg`]="{ item }">
            <v-simple-checkbox
                v-model="item.attachStudentFlg"
                hide-details
                color="indigo"
                style="width: 25px" 
                class="pa-0 ma-0 ml-5"
            />
          </template>
          <!-- 保護者所属チェックボックス -->
          <template #[`item.attachParentFlg`]="{ item }">
            <v-simple-checkbox
                v-model="item.attachParentFlg"
                hide-details
                color="indigo"
                style="width: 25px" 
                class="pa-0 ma-0 ml-5"
            />
          </template>
      </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <div>
          <v-btn color="blue" class="black--text" @click="checkAllStudents" width="90%" :disabled="!isSearch">生徒全選択</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <div>
          <v-btn color="grey" class="black--text" @click="uncheckAllStudents" width="90%" :disabled="!isSearch">生徒全解除</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <div>
          <v-btn color="blue" class="black--text" @click="checkAllParents" width="90%" :disabled="!isSearch">保護者全選択</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <div>
          <v-btn color="grey" class="black--text" @click="uncheckAllParents" width="90%" :disabled="!isSearch">保護者全解除</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2" >
        <div align="left">
          <v-btn color="grey" class="black--text" @click="onClicktoGroupList" width="90%">戻る</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-card-actions>
          <v-radio-group
            v-model="viewHistoryKbn"
            hide-details="auto"
            class="mt-0"
            row
          >
            <v-radio
              label="履歴共有しない"
              value="0"
            ></v-radio>
            <v-radio
              label="履歴共有する"
              value="1"
            ></v-radio>
          </v-radio-group>
        </v-card-actions>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <div align="right">
          <v-btn color="accent" class="black--text" @click="onClicktoGroupAttach" width="90%" :disabled="!isSearch">更新</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
export default {
  props: ['groupName','groupId'],
  data() {
    return {
      // KBK制御
      kateiMst: [],
      buMst: [],
      kaMst: [],
      gakunenMst: [],
      gakunenMstBase: [],
      selectedKatei: "01",
      selectedBu: "",
      selectedKa: "",
      selectedGakunen: "",
      activeClear : false,
      activeSearch : false,
      isSearch : false,

      headers: [
        { text: "学級", value: "gakkyu", align: "start"},
        { text: "出席番号", value: "syussekiNo", align: "start"},
        { text: "生徒氏名", value: "userNmStudent"},
        { text: "保護者氏名", value: "userNmParent"},
        { text: "生徒割当", value: "attachStudentFlg" },
        { text: "保護者割当", value: "attachParentFlg" },
      ],
      userList: [],
      isListLoding: false,
      viewHistoryKbn: "0",
    };
  },
  async created() {
    console.log("props",this.groupName,this.groupId);
    // KBKGCの取得
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    await schoolAidHomeLogined({funcName:"loadGroupAttach"}).then((ret) => {
      this.kateiMst = ret.data.kateiMst;
      this.buMst = ret.data.buMst;
      this.kaMst = ret.data.kaMst;
      this.gakunenMstBase = ret.data.gakunenMst;
    });
  },
  methods: {
    // 抽出条件のKBK変更イベント
    changeKBK() {
      if(this.selectedKatei
       && this.selectedBu
       && this.selectedKa){
        // 学年マスタをKBKでフィルタ
        this.gakunenMst = Enumerable.from(this.gakunenMstBase).where(i => 
        i.code.substring(0, 8) == (
          this.selectedKatei
          + "-"
          + this.selectedBu
          + "-"
          + this.selectedKa
          )).toArray();
        this.gakunenMst.unshift({code: "", name: "", orderNo: ""})
        this.selectedGakunen = "";
      }
      this.activeClear = false;
      this.activeSearch = false;
    },
    changeKBKGC() {
      if(this.selectedKatei
       && this.selectedBu
       && this.selectedKa
       && this.selectedGakunen){
        this.activeSearch = true;
      } else {
        this.activeSearch = false;  
      }
    },
    // クリア
    async onClear() {
      var updateStudents = this.userList.filter(user => user.attachStudentFlg !== user.attachStudentFlgBefore);
      var updateParents = this.userList.filter(user => user.attachParentFlg !== user.attachParentFlgBefore);

      if(updateStudents.length > 0 || updateParents.length > 0){
        var result = confirm("変更した割当は反映されません、よろしいですか？");
        if (!result) {
          return;
        }
      }

      this.userList = [];
      this.activeClear = false;
      this.activeSearch = true;
      this.isSearch = false;
    },
    // 検索
    async onSearch() {
      const data = {
        funcName: "searchGroupAttach",
        groupId: this.groupId,
        selectedKatei: this.selectedKatei,
        selectedBu: this.selectedBu,
        selectedKa: this.selectedKa,
        selectedGakunen: this.selectedGakunen,
      };
      this.userList = [];

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        var buff = [];
        ret.data.forEach((user) => {
          var row = {
            gakkyu: user.gakkyu,
            syussekiNo: user.syussekiNo,
            userIdStudent: user.userIdStudent,
            userNmStudent: user.userNmStudent,
            userIdParent: user.userIdParent,
            userNmParent: user.userNmParent,
            attachStudentFlg: user.attachStudentFlg,
            attachStudentFlgBefore: user.attachStudentFlg,
            attachParentFlg: user.attachParentFlg,
            attachParentFlgBefore: user.attachParentFlg,
          };
          buff.push(row);
        });
        this.userList = buff;
        this.isListLoding = false;
      });
      this.activeClear = true;
      this.activeSearch = false;
      this.isSearch = true;
    },
    checkAllStudents() {
      for (const user of this.userList) {
        user.attachStudentFlg = true;
      }
    },
    uncheckAllStudents() {
      for (const user of this.userList) {
        user.attachStudentFlg = false;
      }
    },
    checkAllParents() {
      for (const user of this.userList) {
        user.attachParentFlg = true;
      }
    },
    uncheckAllParents() {
      for (const user of this.userList) {
        user.attachParentFlg = false;
      }
    },    
  
    async onClicktoGroupAttach() {
      const data=  {
        funcName: "updateGroupAttach",
        groupId: this.groupId,
        viewHistoryKbn: this.viewHistoryKbn,
        updateStudents: this.userList.filter(user => user.attachStudentFlg !== user.attachStudentFlgBefore),
        updateParents: this.userList.filter(user => user.attachParentFlg !== user.attachParentFlgBefore),
      }

      if(data.updateStudents.length == 0 && data.updateParents.length == 0){
        alert("更新対象がありません。");
        return;
      }

      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        if (res.data === "success") {
              alert("保存しました。");
              this.userList = [];
              this.activeClear = false;
              this.activeSearch = true;
              this.isSearch = false;
              this.viewHistoryKbn = "0";
        } else {
              alert("保存に失敗しました");
        }
      });
    },
    // グループ設定画面へ(戻るボタン)
    onClicktoGroupList() {
      this.$router.push({
        name: "GroupList",
      });
    },
  },
};
</script>