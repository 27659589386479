<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center"
        ><v-card class="mx-auto text-center" color="blue-grey" dark
          >グループ一覧</v-card
        ></v-col
      >
    </v-row>
    <v-row dense>
      <v-col cols="6">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="search"
          label="検索"
          placeholder="検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="members"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isListLoding" loading-text="読込中"
          :search="search"
          >
          <template #[`item.favoriteFlg`]="{ item }">
            <v-btn icon @click="changeFavoriteGroup(item)" v-if="item.favoriteFlg"><v-icon color="orange">mdi-star</v-icon></v-btn>
            <v-btn icon @click="changeFavoriteGroup(item)" v-if="!item.favoriteFlg"><v-icon>mdi-star-outline</v-icon></v-btn>
          </template>
          <template #[`item.edit`]="{ item }">
            <!-- ペンアイコン -->
            <div class="Pencil"><v-icon v-if="!item.groupKbn" @click="onClickEditItem(item)">mdi-pencil</v-icon>
            </div> 
          </template>
          <template #[`item.memberList`]="{ item }">
            <v-btn color="accent" class="black--text" large @click="onClicktoGroupMemberList(item)">所属者一覧</v-btn>
          </template>
          <template #[`item.attach`]="{ item }">
            <v-btn color="accent" class="black--text" large v-bind:disabled="item.groupKbn" @click="onClickAttach(item)">生徒・保護者割当</v-btn>
          </template>
          <template #[`item.attachTeachers`]="{ item }">
            <v-btn color="accent" class="black--text" large @click="onClickAttachTeachers(item)">教員割当</v-btn>
          </template>
          <template #[`item.delete`]="{ item }">
            <!-- ゴミ箱アイコン -->
            <div class="Delete"><v-icon v-if="!item.groupKbn" @click="onClickDeleteItem(item)">mdi-delete</v-icon>
            </div> 
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "グループ名", value: "groupName"},
        { text: "代表者", value: "ownerUser"},
        { text: "", value: "edit" },
        { text: "お気に入り", value: "favoriteFlg" },
        { text: "", value: "memberList" },
        { text: "", value: "attach" },
        { text: "", value: "attachTeachers" },
        { text: "", value: "delete" },
      ],
      members: [],
      isListLoding: false,
      perPage: 30,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.perPage = this.$store.state.parPageGroupList;
    await this.reload();
  },
	watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageGroupList', newVal);
    },
  },
  methods: {
    async reload() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined({funcName:"loadGroupList"}).then((groups) => {
        var ret = [];
        groups.data.forEach((group) => {
          var row = {
            groupName: group.groupName,
            ownerUser: group.ownerUser,
            favoriteFlg: group.favoriteFlg,
            groupId : group.gid,
            groupKbn : (group.groupKbn == "0") ? true : false,
          };
          ret.push(row);
        });
        this.members = ret;
        this.isListLoding = false;
      });
    },
    async changeFavoriteGroup(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteGroup",
        groupId: item.groupId,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      this.reload();
    },
    async onClickDeleteItem(item)  {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }
      
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");

      var data = {
        funcName: "deleteGroupList",
        groupId: item.groupId,
      };

      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = true;
        if (res.data == "success") {
          console.log(res.data);
          alert("削除しました");
        } 
        this.$root.loading = false;
      });
      this.reload();
    },
    // 生徒・保護者割当
    onClickAttach(item) {
      console.log(item.groupName,item.groupId);
      this.$router.push({
        name: "GroupAttach",
        params: {
          groupName: item.groupName,
          groupId: item.groupId,
        }
      });
    },
    // 教員割当
    onClickAttachTeachers(item) {
      this.$router.push({
        name: "GroupAttachTeachers",
        params: {
          groupName: item.groupName,
          groupId: item.groupId,
        }
      });
    },
    // イベント設定
    onClickeventRegist(item) {
      console.log("eventsettei",item.groupName,item.groupId);
      this.$router.push({
        name: "EventRegist",
        params: {
          groupName:item.groupName,
          groupId:item.groupId
        }
      });
    },

    onClickEditItem(item) {
      console.log("GroupEdit",item.groupName,item.groupId);
      this.$router.push({
        name: "GroupEdit",
        params: {
          groupName:item.groupName,
          groupId:item.groupId
        }
      });
    },

    onClickAddGroup() {
      this.$router.push({
        name: "GroupRegist",
      })
    },

    // 所属者一覧
    onClicktoGroupMemberList(item) {
      this.$router.push({
        name: "GroupMemberList",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
          beforeName:"GroupList"
        }
      });
    },
  },
};
</script>

<style>
/* .GroupBtn {
  position:absolute;
  right:20%;
} */
.Pencil {
  padding-top: 11px;
  float:left;
  margin-right:10px;
}
.Delete {
  float: right;
  padding-top: 11px;
}
</style>