<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          教師割当({{ groupName }})
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" justify="center">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="search"
          label="検索"
          placeholder="検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="userList"
          :items-per-page="30"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          :loading="isListLoding" loading-text="読込中"
          :search="search"
        >
          <!-- 割当チェックボックス -->
          <template #[`item.attachFlg`]="{ item }">
            <v-simple-checkbox
                v-model="item.attachFlg"
                hide-details
                color="indigo"
                style="width: 25px" 
                class="pa-0 ma-0 ml-5"
            />
          </template>
          <!-- 代表チェックボックス -->
          <template #[`item.ownerFlg`]="{ item }">
            <v-simple-checkbox
                v-model="item.ownerFlg"
                hide-details
                color="indigo"
                style="width: 25px" 
                class="pa-0 ma-0 ml-5"
                @input="changeOwner(item)"
            />
          </template>
          <template #[`item.moderatorFlg`]="{ item }">
            <v-simple-checkbox
                v-model="item.moderatorFlg"
                :disabled="item.ownerFlg"
                hide-details
                color="indigo"
                style="width: 25px" 
                class="pa-0 ma-0 ml-5"
            />
          </template>
      </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
        <div @click="onClicktoGroupList"><v-btn color="grey" class="black--text">戻る</v-btn></div>
      </v-col>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <v-radio-group
          v-model="viewHistoryKbn"
          hide-details="auto"
          class="mt-0"
          row
        >
          <v-radio
            label="履歴共有しない"
            value="0"
          ></v-radio>
          <v-radio
            label="履歴共有する"
            value="1"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="2" md="2" lg="2" xl="2" justify="end">
          <div @click="onClicktoGroupAttach"><v-btn color="accent" class="black--text">更新</v-btn></div>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['groupName','groupId'],
  data() {
    return {
      search : "",
      headers: [],
      userList: [],
      isListLoding: false,
      adminFlg: false,
      groupKbn: "",
      viewHistoryKbn: "0",
    };
  },
  async created() {
    await this.reload();
  },
  methods: {
    // グループ設定画面へ
    onClicktoGroupList() {
      this.$router.push({
        name: "GroupList",
      });
    },
    changeOwner(item) {
      console.log("test");
      for(const user of this.userList){
        // 選択行以外の代表OFFに変更
        // 選択行の割当権限をONに変更
        if(item.userId != user.userId){
          user.ownerFlg = false;
        } else {
          user.moderatorFlg = true;
        }
      }
    },
    // 再描画
    async reload() {
      const data = {
        funcName: "loadGroupAttachTeachers",
        groupId: this.groupId
      };
      this.userList = [];

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        var buff = [];
        ret.data.teachers.forEach((user) => {
          console.log(user);
          var row = {
            userId: user.userId,
            userNm: user.userNm,
            attachFlg: user.attachFlg,
            attachFlgBefore: user.attachFlg,
            ownerFlg: user.ownerFlg,
            ownerFlgBefore: user.ownerFlg,
            moderatorFlg: user.moderatorFlg,
            moderatorFlgBefore: user.moderatorFlg,
          };
          buff.push(row);
        });
        this.adminFlg = ret.data.adminFlg;
        this.groupKbn = ret.data.groupKbn;

        if(this.adminFlg && this.groupKbn == "1"){
          this.headers = [
            { text: "所属する", value: "attachFlg", align: "start", width: "10%"},
            { text: "代表者", value: "ownerFlg", align: "start", width: "10%"},
            { text: "割当者", value: "moderatorFlg", align: "start", width: "10%"},
            { text: "氏名", value: "userNm", align: "start", width: "70%"},
          ];
        } else {
          this.headers = [
            { text: "所属する", value: "attachFlg", align: "start", width: "10%"},
            { text: "氏名", value: "userNm", align: "start", width: "70%"},
          ];
        }
        this.userList = buff;
        this.isListLoding = false;
      });
    },
  
    async onClicktoGroupAttach() {

      const data=  {
        funcName: "updateGroupAttachTeachers",
        groupId: this.groupId,
        viewHistoryKbn: this.viewHistoryKbn,
        updateUsers: this.userList.filter(user => user.attachFlg !== user.attachFlgBefore),
        updateOwner: this.userList.filter(user => user.ownerFlg),
        updateModerators: this.userList.filter(user => user.moderatorFlg),
      }
      console.log(data);
      if(this.adminFlg && this.groupKbn == "1"){
        if(data.updateOwner.length != 1){
          alert("代表者が設定されていません。");
          return;
        }

        if(data.updateModerators.length == 0){
          alert("グループ割当者が設定されていません。");
          return;
        }
      }

      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        if (res.data === "success") {
              alert("保存しました。");
        } else {
              alert("保存に失敗しました");
        }
      });
      this.reload();
    },
  },
};
</script>