<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6">
          連絡登録
        </v-card-title>
        <v-row dense>
          <v-col cols="9" justify="center">
            <div class="mx-10 mt-0">
              <div>対象グループ</div>
              <v-layout wrap>
                {{ selectGroupName }}
              </v-layout>
            </div>
          </v-col>
          <v-col cols="2" justify="center">
            <v-btn color="accent" class="ma-2" :disabled="isTargetSelect" @click="openGroupSelectDialog">
              グループ選択
            </v-btn>
          </v-col>
        </v-row>
        <ValidationProvider name="ユーザー区分" rules="required|checkboxRequired" v-slot="{ errors }">
          <div class="mx-10 mt-10">
            <div>ユーザー区分選択</div>
            <v-layout wrap>
                <v-flex v-for="userKbn in userKbns" :key="userKbn.code" >
                  <v-card-actions>
                    <v-checkbox v-model="selectedUserKbn"
                      :label="userKbn.name"
                      :value="userKbn.code"
                      :disabled="isTargetSelect"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-card-actions>
                </v-flex>
            </v-layout>
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>
        <ValidationProvider name="連絡タイトル" rules="required|max:50" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-text-field
            v-model="title"
            label="連絡タイトル"
            placeholder=""
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>
      <ValidationProvider name="連絡本文" rules="required|max:3000" v-slot="{ errors }">
        <div class="mx-10 mt-0">
          <v-textarea
            v-model="body"
            label="連絡本文"
            placeholder=""
            outlined
            dense
            hide-details="auto"
          ></v-textarea>
        </div> 
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>

      <ValidationProvider name="公開開始日" rules="required" v-slot="{ errors }">
        <div class="mx-10 mt-0"> 
          <v-text-field
            v-model="openDate"
            label="公開開始日"
            placeholder=""
            type="datetime-local"
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
        </div>
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>

      <ValidationProvider name="公開終了日" rules="required|datehourAfter:@公開開始日" v-slot="{ errors }">
        <div class="mx-10 mt-0"> 
          <v-text-field
            v-model="closeDate"
            label="公開終了日"
            placeholder=""
            type="datetime-local"
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
        </div>
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>
      <div class="mx-10 mt-0">
        <v-row>
          <v-col cols="auto">
            <v-card-actions>
              <v-checkbox class="mt-0" v-model="mailSendFlg" label="公開開始にあわせてメールを配信する" hide-details="auto" />
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions>
              <v-checkbox class="mt-0" v-model="commentFlg" label="コメントあり" hide-details="auto" :disabled="!selectCanViewFlg && selectGroupKbn == '0'" />
            </v-card-actions>
          </v-col>
        </v-row>
      </div>
      <v-card-text justify="center">
        <dashboard :uppy="uppy" :props="{
            theme: 'light',
            inline: true,
            width: 3000,
            height: 350,
            disableThumbnailGenerator:true,
            showRemoveButtonAfterComplete:true,
            proudlyDisplayPoweredByUppy:false,
          }" />
          ※添付ファイルは(容量：50MB、個数：10)が上限となっております
      </v-card-text>
      
      <v-card-actions>
        <v-btn color="grey" class="mr-auto mt-0 black--text" @click="canselContactRegist"  width="45%">戻る</v-btn>
        <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveContactRegist" :disabled="invalid" width="45%">登録</v-btn>
      </v-card-actions>  
    </v-card>
   </ValidationObserver>
    <!-- グループ選択ダイアログ -->
    <v-dialog
      v-model="groupSelectDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="ml-5">グループ選択</v-card-title>
        <v-card-text>
          <v-radio-group v-model="selectGroupId"
            :mansdatory="false"
            @change="changeGroupId"
            >
            <v-radio
              v-for="group in groups"
              :key="group.groupId"
              :label="group.groupName"
              :value="group.groupId"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="12" class="ml-1" justify="center">
              <v-btn block color="grey" @click="groupSelectDialog = false">
                閉じる
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Enumerable from "linq";
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";

import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  props: ['groupName','groupId','paramUserKbns','paramTitle','paramBody','paramOpenDate','paramCloseDate','paramTargetIds'],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.fileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.fileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      selectedUserKbn: [],
      userKbns: [
        { code: "0", name: "生徒" },
        { code: "1", name: "保護者" },
        { code: "8", name: "教員" },
      ],
      title: "",
      body: "",
      openDate: "" ,
      closeDate : "",
      fileList: new Set(),
      mailSendFlg: true,
      commentFlg: false,
      groupSelectDialog: false,
      selectGroupName: "",
      selectGroupId: "",
      selectGroupKbn: "",
      selectCanViewFlg: false,
      groups: [],
      targetIds: [],
      isTargetSelect: false,
    };
  },
  async created() {
    this.defaultDateSet() ;
    if(this.paramUserKbns)this.selectedUserKbn = Array.from(this.paramUserKbns);
    if(this.paramTitle)this.title = this.paramTitle;
    if(this.paramBody)this.body = this.paramBody;
    if(this.paramOpenDate)this.openDate = this.paramOpenDate;
    if(this.paramCloseDate)this.closeDate = this.paramCloseDate;
    if(this.paramTargetIds){
      this.targetIds = this.paramTargetIds;
      this.isTargetSelect = true;
    }
    this.selectGroupName = this.groupName;
    this.selectGroupId = this.groupId;

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    await schoolAidHomeLogined({funcName:"loadGroupSelect"}).then((groups) => {
      var ret = [];
      groups.data.forEach((group) => {
        var row = {
          groupId : group.groupId,
          groupName: group.groupName,
          groupKbn: group.groupKbn,
          canViewFlg: group.canViewFlg,
        };

        if(this.groupId == group.groupId){
          this.selectGroupKbn = group.groupKbn;
          this.selectCanViewFlg = group.canViewFlg;
        }
        
        ret.push(row);
      });
      this.groups = ret;
    });
  },
  methods: {
    // 連絡情報登録
    async saveContactRegist() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      console.log(this.selectedUserKbn);
      var data = {
        funcName: "saveContactRegist",
        groupId : this.selectGroupId,
        groupName : this.selectGroupName,
        title : this.title,
        body : this.body,
        openDate : this.openDate,
        closeDate : this.closeDate,
        selectedUserKbn : this.selectedUserKbn, 
        fileList : Array.from(this.fileList),
        mailSendFlg: this.mailSendFlg,
        commentFlg: this.commentFlg,
        targetIds : this.targetIds,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("登録しました。");
          this.$router.push({
            name: "GroupSelect",
          });
        } else {
          alert(res.data);
        }
      });
    },
    canselContactRegist(){
      this.$router.push({
        name: "GroupSelect",
      });
    },
    defaultDateSet() {
      var dt = new Date() ;
      const dtFrom = new Date(dt.setDate(dt.getDate() + 1));
      this.openDate = this.formatDate(dtFrom)  + "T07:00";
      
      dt = new Date() ;
      const dtTo =new Date( dt.setMonth(dt.getMonth()+1)) ;
      this.closeDate = this.formatDate(dtTo) + "T23:59";
      console.log("1月後",this.closeDate);
    } ,
    formatDate(dt){
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    },
    // グループ選択モーダルを開く
    openGroupSelectDialog() {
      this.groupSelectDialog = true;
    },
    // グループ選択時
    changeGroupId() {
      var select = Enumerable.from(this.groups).where(i => i.groupId == this.selectGroupId).toArray();
      this.selectGroupName = select[0].groupName;
      this.selectGroupKbn = select[0].groupKbn;
      this.selectCanViewFlg = select[0].canViewFlg;
      this.commentFlg = false;
    },
  },
};
</script>