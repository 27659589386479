<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack">前の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward">次の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="10" md="10" lg="10" xl="10">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row dense>
            <v-col cols="8">
              <div align="center">
                {{ groupName }}の出欠早退状況({{ yyyymmddDisp }})
              </div>
            </v-col>
            <v-col cols="4">
              <div align="right">
                <v-btn color="grey" small class="black--text" @click="calendar">日付選択</v-btn>

              </div>
            </v-col>
          </v-row>
          <Datepicker
            ref="picker"
            :monday-first="true"
            wrapper-class="calender-text-box-wrapper"
            input-class="calender-text-box"
            placeholder=""
            :language="language"
            @selected=dateSelected />
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" sm="6" md="6" lg="6" xl="6">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="search"
          label="氏名ｶﾅ検索"
          placeholder="氏名ｶﾅ検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="6" xl="6">

        <v-radio-group
          v-model="viewChange"
          row
          hide-details="auto"
        >
          <v-radio
            label="通常"
            value="1"
          ></v-radio>
          <v-radio
            label="パスワード初期化"
            value="2"
          ></v-radio>
          <v-radio
            label="コース選択状況(一覧)"
            value="3"
          ></v-radio>
          <v-radio
            label="コース選択状況(個別相談用)"
            value="4"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="showHeaders"
          :items="members"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          :loading="isListLoding" loading-text="読込中"
          :search="search"
          :custom-filter="filter"
          >
          <template #[`item.userNm`]="{ item }">
            {{ item.userNm }}({{ item.userNmKana }})
          </template>
          <template #[`item.syukketuInputFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.syukketuInputFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.action1`]="{ item }">
            <v-btn color="accent" class="black--text" :disabled="item.pastLockFlg" @click="onClickSyukketu(item)">出欠登録</v-btn>
          </template>
          <template #[`item.syukketuApprovalFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.syukketuApprovalFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.action2`]="{ item }">
            <v-btn color="accent" class="black--text" :disabled="pastLock(item, true)" @click="onClickSyukketuApproval(item)">出欠確定</v-btn>
          </template>
          <template #[`item.sotaiInputFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.sotaiInputFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.action3`]="{ item }">
            <v-btn color="blue" class="black--text" :disabled="item.pastLockFlg" @click="onClickSoutai(item)">早退登録</v-btn>
          </template>
          <template #[`item.sotaiApprovalFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.sotaiApprovalFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.action4`]="{ item }">
            <v-btn color="blue" class="black--text" :disabled="pastLock(item, false)" @click="onClickSoutaiApproval(item)">早退確定</v-btn>
          </template>

          <template #[`item.consentFlg`]="{ item }">
            <span v-if="item.consentFlg">済</span>
            <span v-else class="red--text">未</span>
          </template>
          <template #[`item.mailAddressAuthFlg`]="{ item }">
            <span v-if="item.mailAddressAuthFlg">済</span>
            <span v-else class="red--text">未</span>
          </template>
          <template #[`item.parentConsentFlg`]="{ item }">
            <span v-if="item.parentConsentFlg">済</span>
            <span v-else class="red--text">未</span>
          </template>
          <template #[`item.parentMailAuthFlg`]="{ item }">
            <span v-if="item.parentMailAuthFlg">済</span>
            <span v-else class="red--text">未</span>
          </template>
          <template #[`item.action5`]="{ item }">
            <v-btn color="blue" class="black--text" @click="onClickResetDialog(item)">パスワード初期化</v-btn>
          </template>

          <!-- 未入力時は赤強調 -->
          <template #[`item.status`]="{ item }">
            <span v-if="item.needCourseSelectFlg && !item.existCourseSelectFlg" class="red--text">{{ item.status }}</span>
            <span v-else>{{ item.status }}</span>
          </template>

          <!-- 備考入力時は印表示 -->
          <template #[`item.biko`]="{ item }">
            <span v-if="item.needCourseSelectFlg && item.existCourseSelectFlg && item.biko" class="red--text">※</span>
          </template>

          <template #[`item.action6`]="{ item }">
            <v-btn color="green" class="black--text" @click="onClickViewCourseSelectDialog(item)" v-if="item.existCourseSelectFlg">詳細確認</v-btn>
            <v-btn color="blue" class="black--text" @click="onClickCourseSelectFix(item)" v-if="item.existCourseSelectFlg && !item.courselockFlg">確定</v-btn>
            <v-btn color="red" class="black--text" @click="onClickCourseSelectNotFix(item)" v-if="item.existCourseSelectFlg && item.courselockFlg">確定解除</v-btn>
          </template>
          <template #[`item.action7`]="{ item }">
            <v-btn color="green" class="black--text" @click="onClickViewCourseSelectDialog(item)" v-if="item.existCourseSelectFlg">詳細確認</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="justify-start" dense>
      <v-col>
        <v-btn @click="onClicktoGroupSelect" color="grey" class="black--text" width="352">戻る</v-btn>
      </v-col>
    </v-row>
    <!-- パスワードリセットダイアログ -->
    <v-dialog
      v-model="resetDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="justify-center">パスワードの初期化</v-card-title>
        <v-card-text>
          初期化された後はアカウント通知書に記載のパスワードとなります
          <v-radio-group v-model="resetType" :mansdatory="false">
            <v-radio label="生徒のパスワード" value="0"></v-radio>
            <v-radio label="保護者のパスワード" value="1"></v-radio>
          </v-radio-group>
          <v-btn block color="accent" @click="doReset()">
            初期化する
          </v-btn>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-btn block color="grey" @click="resetDialog = false">
                閉じる
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
      <v-icon color="white">mdi-refresh</v-icon>
    </v-btn>
    <!-- 出欠確定モーダル -->
    <v-dialog
      v-model="attendanceApprovalDialog"
      max-width="290"
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="374"
          color="#fff"
        >
          <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p>{{ yyyymmddDisp }}の出欠確定({{ approvalStudentNm }})</p>
          </v-card-title>

          <v-card-text class="d-flex justify-center flex-column">-
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-select
                    label="出欠登録"
                    item-text="label"
                    item-value="syukketuKbn"
                    v-model="selectedSyukketu"
                    :items="syukketu"
                    outlined
                    disabled
                    hide-details="auto"
                  ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-text-field
                  v-model="biko"
                  label="備考"
                  placeholder="備考"
                  outlined
                  dense
                  :disabled="true"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定出欠"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定出欠"
                      item-text="name"
                      item-value="code"
                      v-model="selectedMain"
                      :items="selectMain"
                      outlined
                      @change="changeMain"
                      hide-details="auto"
                    ></v-select>
                  <p class="error">{{ errors[0] }}</p>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定理由"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定理由"
                      item-text="name"
                      item-value="code"
                      v-model="approvalKbn"
                      :items="selectSubFilter"
                      outlined
                      @change="changeSub"
                      hide-details="auto"
                    ></v-select>
                  <p class="error">{{ errors[0] }}</p>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider v-if="approvalKbn.endsWith('99')" name="その他理由" rules="required|max:500" v-slot="{ errors }">
                  <v-text-field
                    v-model="approvalText"
                    label="その他理由"
                    placeholder="その他理由"
                    outlined
                    dense
                    hide-details="auto"
                    v-if="approvalKbn.endsWith('99')"
                  ></v-text-field>
                  <p v-if="approvalKbn.endsWith('99')" class="error">{{ errors[0] }}</p>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" class="black--text" @click="attendanceApprovalDialog = false" width="49%">
              閉じる
            </v-btn>
            <v-btn color="accent" class="black--text" @click="syukketuApprovalSave()" :disabled="invalid" width="49%">
              確定
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
    <!-- 早退確定モーダル -->
    <v-dialog
      v-model="leavingApprovalDialog"
      max-width="290"
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="374"
          color="#fff"
        >
          <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p>{{ yyyymmddDisp }}の早退確定({{ approvalStudentNm }})</p>
          </v-card-title>

          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-text-field
                  v-model="leavingReason"
                  label="早退事由"
                  placeholder="早退事由"
                  outlined
                  dense
                  :disabled="true"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-text-field
                  v-model="leavingTime"
                  label="下校開始予定時刻"
                  placeholder="下校開始予定時刻"
                  outlined
                  dense
                  :disabled="true"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定出欠"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定出欠"
                      item-text="name"
                      item-value="code"
                      v-model="selectedMain"
                      :items="selectMain"
                      outlined
                      @change="changeMain"
                      hide-details="auto"
                    ></v-select>
                  <p class="error">{{ errors[0] }}</p>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定理由"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定理由"
                      item-text="name"
                      item-value="code"
                      v-model="approvalKbn"
                      :items="selectSubFilter"
                      outlined
                      @change="changeSub"
                      hide-details="auto"
                    ></v-select>
                  <p class="error">{{ errors[0] }}</p>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider v-if="approvalKbn.endsWith('99')" name="その他理由" rules="required|max:500" v-slot="{ errors }">
                  <v-text-field
                    v-model="approvalText"
                    label="その他理由"
                    placeholder="その他理由"
                    outlined
                    dense
                    v-if="approvalKbn.endsWith('99')"
                    hide-details="auto"
                  ></v-text-field>
                  <p v-if="approvalKbn.endsWith('99')" class="error">{{ errors[0] }}</p>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" class="black--text" @click="leavingApprovalDialog = false" width="49%">
              閉じる
            </v-btn>
            <v-btn color="accent" class="black--text" @click="sotaiApprovalSave()" :disabled="invalid" width="49%">
              確定
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!-- コース選択詳細確認ダイアログ -->
    <v-dialog
      v-model="viewCourseSelectDialog"
      fullscreen
    >
      <v-card>
        <v-card-title class="justify-center">コース選択詳細</v-card-title>
        <v-card-text class="text-pre-wrap">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              生徒氏名：{{ dialogViewUserNm }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              大学区分：{{ dialogViewDaigakuKbnName }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              志望する文理コース：{{ dialogViewSentakuCourseCdName }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(数学)：{{ dialogViewYobi1 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(理科)：{{ dialogViewYobi2 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(地公)：{{ dialogViewYobi3 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目(芸術)：{{ dialogViewYobi4 }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              備考：
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              {{ dialogViewBiko }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn color="grey" class="black--text" @click="viewCourseSelectDialog = false" width="100%">
              閉じる
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import Enumerable from "linq";
export default {
  props: ['groupName','groupId','param_yyyymmdd'],
  watch: {
    yyyymmdd: async function(newVal, oldVal) {
      console.log(newVal, oldVal)
      await this.reload();
    },
    perPage: function(newVal) {
      this.$store.dispatch('setParPageUserList', newVal);
    },
  },
  computed: {
    showHeaders () {
      if(this.viewChange == "1"){
        return this.headers1;
      } else if(this.viewChange == "2"){
        return this.headers2;
      } else if(this.viewChange == "3"){
        return this.headers3;
      } else {
        return this.headers4;
      }
    }
  },
  data() {
    return {
      search: "",
      yyyymmdd: "",
      yyyymmddDisp: "",
      viewChange: "1",
      headers1: [
        { text: "氏名(ｶﾅ)", value: "userNm", align: "start", width: "30%" },
        { text: "学年", value: "gakunen", width: "10%" },
        { text: "学級", value: "gakkyu", width: "10%" },
        { text: "出席番号", value: "syussekiNo", width: "10%" },
        { text: "出欠入力", value: "syukketuInputFlg", width: "5%" },
        { text: "", value: "action1", width: "5%" },
        { text: "出欠確定", value: "syukketuApprovalFlg", width: "5%" },
        { text: "", value: "action2", width: "5%" },
        { text: "早退入力", value: "sotaiInputFlg", width: "5%" },
        { text: "", value: "action3", width: "5%" },
        { text: "早退確定", value: "sotaiApprovalFlg", width: "5%" },
        { text: "", value: "action4", width: "5%" },
      ],
      headers2: [
        { text: "氏名(ｶﾅ)", value: "userNm", align: "start", width: "30%" },
        { text: "学年", value: "gakunen", width: "10%" },
        { text: "学級", value: "gakkyu", width: "10%" },
        { text: "出席番号", value: "syussekiNo", width: "10%" },
        { text: "生徒初回ログイン", value: "consentFlg", width: "10%" },
        { text: "生徒メール認証", value: "mailAddressAuthFlg", width: "10%" },
        { text: "保護者初回ログイン", value: "parentConsentFlg", width: "10%" },
        { text: "保護者メール認証", value: "parentMailAuthFlg", width: "10%" },
        { text: "", value: "action5", width: "5%" },
      ],
      headers3: [
        { text: "氏名(ｶﾅ)", value: "userNm", align: "start", width: "15%" },
        { text: "学年", value: "gakunen", width: "5%" },
        { text: "学級", value: "gakkyu", width: "5%" },
        { text: "出席番号", value: "syussekiNo", width: "5%" },
        { text: "選択状況", value: "status", width: "5%" },
        { text: "大学区分", value: "daigakuKbnName", width: "10%" },
        { text: "文理コース", value: "sentakuCourseCdName", width: "5%" },
        { text: "選択科目(数学)", value: "yobi1", width: "10%" },
        { text: "選択科目(理科)", value: "yobi2", width: "10%" },
        { text: "選択科目(地公)", value: "yobi3", width: "10%" },
        { text: "選択科目(芸術)", value: "yobi4", width: "10%" },
        { text: "備考有無", value: "biko", width: "5%" },
        { text: "", value: "action6", width: "10%" },
      ],
      headers4: [
        { text: "氏名(ｶﾅ)", value: "userNm", align: "start", width: "15%" },
        { text: "学年", value: "gakunen", width: "5%" },
        { text: "学級", value: "gakkyu", width: "5%" },
        { text: "出席番号", value: "syussekiNo", width: "5%" },
        { text: "選択状況", value: "status", width: "5%" },
        { text: "備考有無", value: "biko", width: "5%" },
        { text: "", value: "action7", width: "10%" },
      ],
      members: [],
      isListLoding: false,
      resetUserId: "",
      resetType: "0",
      resetDialog: false,
      language:{
        language: 'Japanese',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        days: ['日', '月', '火', '水', '木', '金', '土'],
        yearSuffix: '年',
        ymd: true,
      },
      perPage: 30,

      // 出欠確定用
      attendanceApprovalDialog: false,
      selectedSyukketu: { label: "", syukketuKbn: 0 }, //初期値
      syukketu: [
        { label: "", syukketuKbn: 0 },
        { label: "欠席", syukketuKbn: 1 },
        { label: "遅刻", syukketuKbn: 5 },
        { label: "早退予定", syukketuKbn: 6 },
      ],
      biko: "",
      // 早退確定用
      leavingApprovalDialog: false,
      leavingReason: "",
      leavingTime: "",
      leavingMailFlg: false,
      // 出欠早退確定共通
      approvalStudentId: "",
      approvalStudentNm: "",
      selectMain: [],
      selectedMain: "", //初期値
      selectSub: [],
      selectSubFilter: [],
      approvalKbn: "", //初期値
      approvalText: "", //初期値

      viewCourseSelectDialog: false,
      dialogViewUserNm: "",
      dialogViewDaigakuKbnName: "",
      dialogViewSentakuCourseCdName: "",
      dialogViewYobi1: "",
      dialogViewYobi2: "",
      dialogViewYobi3: "",
      dialogViewYobi4: "",
      dialogViewBiko: "",
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // グループ未選択は選択画面へ
    if(!this.groupId){
      this.onClicktoGroupSelect();
    }
    this.perPage = this.$store.state.parPageUserList;
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
  },
  methods: {
    // ページ再読込
    async reload() {
      this.members = [];
      console.log("props",this.groupName,this.groupId);
      const data = {
        funcName: "loadUserList",
        groupId: this.groupId,
        yyyymmdd: this.yyyymmdd
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((users) => {
        var ret = [];
        users.data.forEach((user) => {
          var row = {
            consentFlg: user.consentFlg,
            mailAddressAuthFlg: user.mailAddressAuthFlg,
            userNm: user.userNm,
            userNmKana: user.userNmKana,
            gakunen: user.gakunen,
            gakkyu: user.gakkyu,
            syussekiNo: user.syussekiNo,
            studentId : user.loginId,
            parentConsentFlg: user.parentConsentFlg,
            parentMailAuthFlg: user.parentMailAuthFlg,
            syukketuInputFlg: user.syukketuInputFlg,
            sotaiInputFlg: user.sotaiInputFlg,
            syukketuApprovalFlg: user.syukketuApprovalFlg,
            sotaiApprovalFlg: user.sotaiApprovalFlg,
            groupId : this.groupId,
            pastLockFlg: user.pastLockFlg,
            // コース選択関連情報
            needCourseSelectFlg: user.needCourseSelectFlg,
            status: user.status,
            daigakuKbnName: user.daigakuKbnName,
            sentakuCourseCdName: user.sentakuCourseCdName,
            yobi1: user.yobi1,
            yobi2: user.yobi2,
            yobi3: user.yobi3,
            yobi4: user.yobi4,
            biko: user.biko,
            courselockFlg: user.courselockFlg,
            existCourseSelectFlg: user.existCourseSelectFlg,
          };
          ret.push(row);
        });
        this.isListLoding = false;
        this.members = ret;
        console.log("groupId",this.members.groupId) ;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // 氏名カナ検索
    filter(value, search, item) {
      if(!item.userNmKana)return false;
      return item.userNmKana.toString().includes(search)
    },
    // データピッカー開閉
    calendar() {
      if(!this.$refs.picker.isOpen) {
          this.$refs.picker.$el.querySelector("input").focus();
          this.$refs.picker.showCalendar();
      } else {
          this.$refs.picker.close();
      }
    },
    // データピッカー日付選択
    dateSelected(selectedDate) {
      this.yyyymmdd = format(selectedDate, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(selectedDate, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 前の日へボタン
    toBack() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() - 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 次の日へボタン
    toForward() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() + 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // パスワード初期化
    async doReset() {
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "initPassword",
        resetType: this.resetType,
        studentId: this.resetUserId,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        alert("パスワードを初期化しました");
      }).catch((e) => {
        console.log(e.message);
        this.$root.loading = false;
      });
    },
    // 出欠訂正
    onClickSyukketu(item) {
      this.$router.push({
        name: "AttendanceEdit",
        params: {
          studentId: item.studentId,
          studentName: item.userNm,
          groupName: this.groupName,
          groupId: this.groupId,
          param_yyyymmdd: this.yyyymmdd,
        }
      });
    },
    // 早退登録
    onClickSoutai(item) {
      console.log(item.studentId);
      this.$router.push({
        name: "LeavingRegist",
        params: {
          studentId: item.studentId,
          studentName: item.userNm,
          groupName: this.groupName,
          groupId: this.groupId,
          parentMailAuthFlg: item.parentMailAuthFlg,
          param_yyyymmdd: this.yyyymmdd,
        }
      });
    },
    // 出欠早退初期処理
    async syukketuSotaiLoad(funcName, syukketuSotai) {
      // ローディング開始
      this.$root.loading = true;

      // 初期化
      this.selectMain = [];
      this.selectedMain = "";
      this.selectSub = [];
      this.selectSubFilter = [];
      this.approvalKbn = "";
      this.approvalText = "";

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: funcName,
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId
      };

      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        if (syukketuSotai) {
          this.selectedSyukketu = res.data.syukketuKbn;
          this.biko = res.data.biko;
        } else {
          this.leavingReason = res.data.leavingReason;
          this.leavingTime = res.data.leavingTime;
        }
  
        this.selectMain = [{code: "", name: "", orderNo: "", yobi1: ""}].concat(res.data.selectMain);
        this.selectSub = res.data.selectSub;
        this.selectedMain = res.data.approvalKbn.substring(0, 2);
        if(res.data.approvalKbn != ""){
          this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
        }

        this.approvalKbn = res.data.approvalKbn;
        this.approvalText = res.data.approvalText;

      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });
    },
    // 出欠早退情報保存
    async syukketuSotaiSave(funcName, syukketuSotai) {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: funcName,
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId,
        approvalKbn: this.approvalKbn,
        approvalText: this.approvalText,
      };

      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        console.log(res.data);
        if (res.data) {
          alert("登録しました");

          // reloadせずにapprovalFlgの画面更新
          this.members.forEach((doc) => {
            if (doc.studentId === this.approvalStudentId) {
              if (syukketuSotai) {
                doc.syukketuApprovalFlg = res.data.approvalFlg;
              } else {
                doc.sotaiApprovalFlg = res.data.approvalFlg;
              }
            }
          });
        }
      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });
    },
    // 出欠確定
    async onClickSyukketuApproval(item) {
      this.approvalStudentId = item.studentId;
      this.approvalStudentNm = item.userNm;
      this.attendanceApprovalDialog = true;

      // 出欠早退初期処理
      await this.syukketuSotaiLoad("loadAttendanceApproval", true);
    },
    // 出欠情報設定保存
    async syukketuApprovalSave() {
      await this.syukketuSotaiSave("saveAttendanceApproval", true);
      this.attendanceApprovalDialog = false;
    },
    // 早退確定
    async onClickSoutaiApproval(item) {
      console.log(item.studentId);
      this.approvalStudentId = item.studentId;
      this.approvalStudentNm = item.userNm;
      this.leavingApprovalDialog = true;

      // 出欠早退初期処理
      await this.syukketuSotaiLoad("loadLeavingApproval");
    },
    // 早退情報設定保存
    async sotaiApprovalSave() {
      await this.syukketuSotaiSave("saveLeavingApproval", false);
      this.leavingApprovalDialog = false;
    },
    async changeMain() {
      this.approvalKbn = "";
      this.approvalText = "";
      this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
    },
    async changeSub() {
      if(!this.approvalKbn.endsWith('99')){
        this.approvalText = "";
      }
    },
    // グループ選択に戻る
    onClicktoGroupSelect(){
      this.$router.push({
        name: "GroupSelect"
      });
    },
    onClickResetDialog(item){
      console.log(item);
      this.resetUserId = item.studentId;
      this.consentFlg = item.consentFlg;
      this.mailAddressAuthFlg = item.mailAddressAuthFlg;
      this.parentConsentFlg = item.parentConsentFlg;
      this.parentMailAuthFlg = item.parentMailAuthFlg;
      this.resetDialog = true;
    },
    async onClickCourseSelectFix(item){
      // ローディング開始
      this.$root.loading = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "courseSelectFix",
        studentId: item.studentId,
      };
      await schoolAidHomeLogined(data).then(() => {
        alert("確定しました");
      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });

      // ローディング開始
      this.$root.loading = false;

      await this.reload();
    },
    async onClickCourseSelectNotFix(item){
      // ローディング開始
      this.$root.loading = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "courseSelectNotFix",
        studentId: item.studentId,
      };
      await schoolAidHomeLogined(data).then(() => {
        alert("確定解除しました");
      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });

      // ローディング開始
      this.$root.loading = false;

      await this.reload();
    },
    onClickViewCourseSelectDialog(item){
      this.dialogViewUserNm = item.userNm
      this.dialogViewDaigakuKbnName = item.daigakuKbnName;
      this.dialogViewSentakuCourseCdName = item.sentakuCourseCdName;
      this.dialogViewYobi1 = item.yobi1;
      this.dialogViewYobi2 = item.yobi2;
      this.dialogViewYobi3 = item.yobi3;
      this.dialogViewYobi4 = item.yobi4;
      this.dialogViewBiko = item.biko;
      this.viewCourseSelectDialog = true;
    },
    // 学期内ボタン制御
    pastLock(item, syukketu){
      var flg = false;
      if (syukketu) {
        // 出欠
        if (item.pastLockFlg) {
          flg = item.pastLockFlg;
        } else {
          flg = item.syukketuInputFlg ? false : true;
        }
      } else {
        // 早退
        if (item.pastLockFlg) {
          flg = item.pastLockFlg;
        } else {
          flg = item.sotaiInputFlg ? false : true;
        }
      }

      return flg;
    },
  },
};
</script>

<style>
.calender-text-box {
    display: none;
}
.vdp-datepicker__calendar {
    width: 100% !important;
}
</style>