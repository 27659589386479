<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          対象選択({{ groupName }})
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <ValidationObserver ref="obs" v-slot="{ invalid }">
          <v-card class="mx-auto text-center">
            <v-row dense class="justify-center"> 
              <v-col cols="12" sm="2" md="2" lg="2" xl="2" justify="center">
                <v-layout wrap><v-card-actions class="justify-center">ユーザー区分選択</v-card-actions></v-layout>
              </v-col>
              <v-col cols="12" sm="10" md="10" lg="10" xl="10" justify="center">
              <ValidationProvider name="ユーザー区分" rules="required|checkboxRequired" v-slot="{ errors }">
                <v-layout wrap>
                  <v-flex v-for="userKbn in userKbns" :key="userKbn.code" >
                    <v-card-actions class="justify-center">
                    <v-checkbox v-model="selectedUserKbn"
                      :label="userKbn.name"
                      :value="userKbn.code"
                      :disabled="isSearch"
                      hide-details="auto"
                    ></v-checkbox>
                    </v-card-actions>
                  </v-flex>
                </v-layout>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" justify="center">
                <v-btn color="grey" class="ml-auto mt-0 white--text" @click="toGroupSelect" width="90%">戻る</v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" justify="center">
                <v-btn color="accent" class="ml-auto mt-0 black--text" @click="searchTargetSelect" :disabled="invalid || isSearch" width="90%">検索</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </ValidationObserver>
      </v-col>
    </v-row>
    <v-row dense>
      <v-tabs class="mx-2 mt-2"
        v-model="tabSelect"
        fixed-tabs
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows>
        <v-tab :disabled="!viewTab1" href="#students">
          生徒
          <v-btn color="blue" icon outlined @click="checkAllStudents" :disabled="!isSearch"><v-icon>mdi-plus</v-icon></v-btn>
          <v-btn color="red" icon outlined @click="uncheckAllStudents" :disabled="!isSearch"><v-icon>mdi-minus</v-icon></v-btn>
        </v-tab>
        <v-tab :disabled="!viewTab2" href="#parents">
          保護者
          <v-btn color="blue" icon outlined @click="checkAllParents" :disabled="!isSearch"><v-icon>mdi-plus</v-icon></v-btn>
          <v-btn color="red" icon outlined @click="uncheckAllParents" :disabled="!isSearch"><v-icon>mdi-minus</v-icon></v-btn>
        </v-tab>
        <v-tab :disabled="!viewTab3" href="#teachers">
          教職員
          <v-btn color="blue" icon outlined @click="checkAllTeachers" :disabled="!isSearch"><v-icon>mdi-plus</v-icon></v-btn>
          <v-btn color="red" icon outlined @click="uncheckAllTeachers" :disabled="!isSearch"><v-icon>mdi-minus</v-icon></v-btn>
        </v-tab>

        <v-tab-item value="students">
          <v-col cols="12">
            <v-data-table
              :headers="studentHed"
              :items="students"
              :items-per-page="10"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              :loading="isListLoding" loading-text="読込中">
              <template #[`item.targetFlg`]="{ item }">
                <v-simple-checkbox
                    v-model="item.targetFlg"
                    hide-details
                    color="indigo"
                    style="width: 25px" 
                    class="pa-0 ma-0 ml-5"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-tab-item>
        <v-tab-item value="parents">
          <v-col cols="12">
            <v-data-table
              :headers="parentsHed"
              :items="parents"
              :items-per-page="10"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              :loading="isListLoding" loading-text="読込中">
              <template #[`item.students`]="{ item }">
                <v-row dense v-for="student in item.studentsInfo" :key="student.loginId" >
                  <v-col cols="12" justify="center">
                    {{ student.userNm }}/{{ student.kateiNm }}/{{ student.buNm }}/{{ student.kaNm }}/{{ student.gakunen }}/{{ student.gakkyu }}/{{ student.syussekiNo }}
                  </v-col>
                </v-row>
              </template>
              <template #[`item.targetFlg`]="{ item }">
                <v-simple-checkbox
                    v-model="item.targetFlg"
                    hide-details
                    color="indigo"
                    style="width: 25px" 
                    class="pa-0 ma-0 ml-5"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-tab-item>
        <v-tab-item value="teachers">
          <v-col cols="12">
            <v-data-table
              :headers="teacherHed"
              :items="teachers"
              :items-per-page="10"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              :loading="isListLoding" loading-text="読込中">
              <template #[`item.targetFlg`]="{ item }">
                <v-simple-checkbox
                    v-model="item.targetFlg"
                    hide-details
                    color="indigo"
                    style="width: 25px" 
                    class="pa-0 ma-0 ml-5"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-tab-item>
      </v-tabs>
    </v-row>
    <v-row dense :justify="center">
      <v-col cols="6">
        <v-btn color="accent" class="ml-auto mt-0 black--text" @click="toContactRegist" :disabled="!isSearch" width="90%">連絡登録</v-btn>
      </v-col>
      <v-col cols="6" justify="center">
        <v-btn color="accent" class="ml-auto mt-0 black--text" @click="toSurveyRegist" :disabled="!isSearch" width="90%">アンケート登録</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['groupId','groupName'],
  data() {
    return {
      selectedUserKbn: [],
      userKbns: [
        { code: "0", name: "生徒" },
        { code: "1", name: "保護者" },
        { code: "8", name: "教員" },
      ],
      studentHed: [
        { text: "氏名", value: "userNm", align: "start"},
        { text: "課程", value: "kateiNm" },
        { text: "部", value: "buNm" },
        { text: "科", value: "kaNm" },
        { text: "学年", value: "gakunen" },
        { text: "学級", value: "gakkyu" },
        { text: "出席番号", value: "syussekiNo" },
        { text: "対象", value: "targetFlg" },
      ],
      students: [],
      parentsHed: [
        { text: "生徒(氏名/課程/部/科/学年/学級/出席番号)", value: "students" },
        { text: "保護者氏名", value: "userNm", align: "start"},
        { text: "対象", value: "targetFlg" },
      ],
      parents: [],
      teacherHed: [
        { text: "氏名", value: "userNm", align: "start"},
        { text: "対象", value: "targetFlg" },
      ],
      teachers: [],
      isListLoding: false,
      isSearch : false,
      viewTab1 : false,
      viewTab2 : false,
      viewTab3 : false,
      tabSelect : "",
    };
  },
  // 初期表示時データ読み込み
  async created() {

  },
  methods: {
    async searchTargetSelect(){
        const data = {
        funcName: "searchTargetSelect",
        groupId: this.groupId,
        userKbns: this.selectedUserKbn, 
      };
      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        console.log(ret);
        this.students = ret.data.students;
        this.parents = ret.data.parents;
        this.teachers = ret.data.teachers;
        if(this.teachers.length > 0){
          this.viewTab3 = true;
          this.tabSelect = "teachers";
        }
        if(this.parents.length > 0){
          this.viewTab2 = true;
          this.tabSelect = "parents";
        }
        if(this.students.length > 0){
          this.viewTab1 = true;
          this.tabSelect = "students";
        }
        this.isListLoding = false;
        this.isSearch = true;
      });
    },
    checkAllStudents() {
      for (const user of this.students) {
        user.targetFlg = true;
      }
    },
    uncheckAllStudents() {
      for (const user of this.students) {
        user.targetFlg = false;
      }
    },
    checkAllParents() {
      for (const user of this.parents) {
        user.targetFlg = true;
      }
    },
    uncheckAllParents() {
      for (const user of this.parents) {
        user.targetFlg = false;
      }
    }, 
    checkAllTeachers() {
      for (const user of this.teachers) {
        user.targetFlg = true;
      }
    },
    uncheckAllTeachers() {
      for (const user of this.teachers) {
        user.targetFlg = false;
      }
    }, 
    toGroupSelect(){
      this.$router.push({
        name: "GroupSelect",
      });
    },
    toContactRegist(){
      var targetIds = [];
      for (const user of this.students) {
        if(user.targetFlg)targetIds.push(user.loginId);
      }
      for (const user of this.parents) {
        if(user.targetFlg)targetIds.push(user.loginId);
      }
      for (const user of this.teachers) {
        if(user.targetFlg)targetIds.push(user.loginId);
      }
      if (targetIds.length == 0) {
        alert("対象を選択してください");
        return;
      }
      this.$router.push({
        name: "ContactRegist",
        params: {
          groupName:this.groupName,
          groupId:this.groupId,
          paramUserKbns:this.selectedUserKbn,
          paramTargetIds:targetIds,
        }
      });
    },
    toSurveyRegist(){
      var targetIds = [];
      for (const user of this.students) {
        if(user.targetFlg)targetIds.push(user.loginId);
      }
      for (const user of this.parents) {
        if(user.targetFlg)targetIds.push(user.loginId);
      }
      for (const user of this.teachers) {
        if(user.targetFlg)targetIds.push(user.loginId);
      }
      if (targetIds.length == 0) {
        alert("対象を選択してください");
        return;
      }
      this.$router.push({
        name: "SurveyRegist",
        params: {
          groupName:this.groupName,
          groupId:this.groupId,
          paramUserKbns:this.selectedUserKbn,
          paramTargetIds:targetIds,
        }
      });
    },    
  },
};
</script>