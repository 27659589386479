<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack" :disabled="lockBack">前の連絡へ</v-btn>
        </div>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward" :disabled="lockForward">次の連絡へ</v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- 連絡ブロック -->
    <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6">{{ title }}</v-card-title>
        <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0">投稿者：{{ createUser }}</v-card-text>
        <v-card-text class="d-flex justify-center flex-column">
          <v-textarea 
            auto-grow
            readonly
            label="連絡本文"
            style="white-space:pre;"
            v-model="body"
            class="text-body-2"
            hide-details="auto"
          >
          </v-textarea>
        </v-card-text>   
        <v-card
          class="d-flex justify-center flex-column mx-auto my-0 flat"
          width="1200"
          color="#fff"
        >
        <v-list>
          <v-subheader>添付ファイル</v-subheader>
          <v-list-item-group  v-if="fileList.length" color="primary">
            <v-list-item v-for="item in fileList" :key="item.attachmentId">
              <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group  v-if="!fileList.length" color="primary">
            <v-list-item>ファイルはありません</v-list-item>
          </v-list-item-group>
        </v-list>

      </v-card>     
    </v-card>
    <!-- コメントブロック -->
    <v-card
      v-if="this.commentFlg"
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="1200"
      color="#fff"
    >
      <v-card-title class="d-flex justify-center pa-0 mt-6">コメント</v-card-title>
      <v-card
          v-if="!commentList.length"
          class="d-flex justify-center flex-column mx-auto my-0 flat"
          width="1200"
          color="#fff"
        >
        <v-card-text class="d-flex justify-center flex-column">
          <label color="primary">コメントはありません</label>
        </v-card-text>
      </v-card>
      <v-card
          v-if="commentList.length"
          class="d-flex justify-center flex-column mx-auto my-0 flat overflow-y-auto overflow-x-hidden"
          width="1200"
          max-height="1000"
          color="#fff"
        >
        <div v-for="item in commentList" :key="item.commentId">
          <v-row dense>
            <v-col cols="12" class="d-flex ml-2 pb-0 pt-5">
              <span style="color:#160;">{{ item.userNm }}</span>が
              <span style="color:#160;">{{ item.postDate }}</span>に更新
            </v-col>
          </v-row>
          <v-row dense v-if="item.fileList.length > 0">
            <v-col cols="12" class="d-flex ml-2 pb-0 pt-0">
              添付ファイル
              <div v-for="file in item.fileList" :key="file.attachmentId">
                <v-btn icon @click="onClickDwonload(file)"><v-icon color="black">mdi-file-download</v-icon></v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters><v-divider></v-divider></v-row>
          <v-row dense>
            <v-card-text class="d-flex justify-center flex-column pt-0">
              <v-row dense>
                <v-col cols="12">
                  <v-textarea 
                    auto-grow
                    readonly
                    label=""
                    style="white-space:pre;"
                    v-model="item.comment"
                    class="text-body-2 pt-0"
                    hide-details="auto"
                  >
                    <template v-slot:append-outer v-if="item.editFlg">
                      <v-card-actions class="justify-center pl-0 pr-0">
                        <v-icon class="ml-2" @click="onClickEditItem(item)">mdi-pencil</v-icon>
                      </v-card-actions>
                      <v-card-actions class="justify-center pl-0 pr-0">
                        <v-icon class="ml-2" @click="onClickDeleteItem(item)">mdi-delete</v-icon>
                      </v-card-actions>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </div>
      </v-card>
      <v-divider></v-divider>
      <v-card
        class="d-flex justify-center flex-column mx-auto pa-2 flat"
        width="1200"
        color="#fff"
      >
        <ValidationProvider name="コメント本文" rules="max:3000" v-slot="{ errors }">
        <v-row justify="end">
          <v-col cols="12" class="d-flex text-right">
            ※添付ファイルは(容量：50MB、個数：10)が上限となっております
            <v-btn icon @click="openModal" class="ml-auto"><v-icon color="black">mdi-paperclip</v-icon></v-btn>
            <div v-if="uploadFileList.size == 0">添付ファイルなし</div>
            <div v-if="uploadFileList.size > 0"><p class="font-weight-bold red--text">添付ファイルあり</p></div>
            <v-btn color="accent" class="ml-auto mt-0 black--text" @click="sendComment" :disabled="invalid">送信</v-btn>
          </v-col>
          <dashboard-modal
            :uppy="uppy"
            :open="viewModal"
            :props="{
              theme: 'light',
              disableThumbnailGenerator:true,
              showRemoveButtonAfterComplete:true,
              proudlyDisplayPoweredByUppy:false,
              onRequestCloseModal: closeModal
            }" />
        </v-row>
        <v-row class="pt-0 mt-0">
          <v-col cols="12" class="pb-0">
            <v-textarea
              v-model="commentBody"
              label="コメント本文"
              placeholder=""
              outlined
              dense
              class="text-body-2 pt-0 mb-0"
              hide-details="auto"
            >
            </v-textarea>
            <p class="error">{{ errors[0] }}</p>
          </v-col>
        </v-row>

        </ValidationProvider>
      </v-card>
    </v-card>
    <v-btn v-if="this.commentFlg" fixed fab bottom color="secondary" @click="loadCommentArea" style="bottom: 50px">
      <v-icon color="white">mdi-refresh</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { DashboardModal } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";

import { saveAs } from 'file-saver';
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['messageId','contactListBuff','contactListIndex'],
  // ファイルアップロード実装 START-------------------------
  components: {
    DashboardModal
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      this.uploadFileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      console.log("file-removed");
      this.uploadFileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      title : "",
      body : "",
      contactId : "",
      groupId : "",
      createUser : "",
      fileList: [],
      commentFlg: false,
      commentList: [],
      commentBody: "",
      uploadFileList: new Set(),
      viewModal: false,
      lockBack: false,
      lockForward: false,
    };
  },
  watch: {
    messageId: async function () {
      await this.load();
    }
  },
  // 初期表示時データ読み込み
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.lockBack = (this.contactListIndex == 0)?true:false;
      this.lockForward = (this.contactListBuff.length == (this.contactListIndex+1))?true:false;
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "loadContactDetails",
        messageId : this.messageId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.title = doc.data.title;
        this.body = doc.data.body;
        this.contactId = doc.data.contactId;
        this.groupId = doc.data.groupId;
        this.createUser = doc.data.createUser;
        this.fileList = doc.data.fileList;
        this.commentFlg = doc.data.commentFlg;
      });

      // コメント情報取得
      if (this.commentFlg) {
        await this.loadCommentArea();
        this.commentBody = "";
        this.uppy.cancelAll();
      }

      // ローディング非表示
      this.$root.loading = false;
    },
    // 前の連絡へボタン
    toBack() {
      this.$router.push({
        name: "ContactDetails",
        params: {
          messageId: this.contactListBuff[this.contactListIndex - 1],
          contactListBuff: this.contactListBuff,
          contactListIndex: this.contactListIndex - 1,
        } 
      });
    },
    // 次の連絡へボタン
    toForward() {
      this.$router.push({
        name: "ContactDetails",
        params: {
          messageId: this.contactListBuff[this.contactListIndex + 1],
          contactListBuff: this.contactListBuff,
          contactListIndex: this.contactListIndex + 1,
        } 
      });
    },
    async loadCommentArea() {
      this.commentList = [];
      console.log("this.contactId：", this.contactId);
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "loadCommentArea",
        contactId : this.contactId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.commentList = doc.data ? doc.data : [];
      });
    },

    async onClickDwonload(item) {
      saveAs(item.url, item.fileName);
    },

    // コメント送信
    async sendComment() {
      if (!this.commentBody) {
        alert("コメント本文が入力されていません。");
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";

      var param = {
        funcName: "sendComment",
        contactId: this.contactId,
        commentId: this.saveCommentId,
        comment: this.commentBody,
        fileList : Array.from(this.uploadFileList),
      };

      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.loadCommentArea();
      this.commentBody = "";
      this.uppy.cancelAll();
    },

    // コメント修正
    onClickEditItem(item) {
      console.log("item：", item);
      this.saveCommentId = item.commentId;
      this.commentBody = item.comment;
    },

    // 論理削除
    async onClickDeleteItem(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "deleteComment",
        contactId: this.contactId,
        commentId: item.commentId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.loadCommentArea();
    },
    openModal() { this.viewModal = true },
    closeModal() { this.viewModal = false },
  },  
};
</script>