<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-data-table 
          :headers="headers"
          :items="events"
          :items-per-page="30"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          caption="イベント一覧"
          disable-sort
          :loading="isEventsLoding" loading-text="読込中"
          >
        </v-data-table>
      </v-col>
    </v-row>
    <v-btn fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
      <v-icon color="white">mdi-refresh</v-icon>
    </v-btn>
  </v-container>
</template>
    
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      headers: [
        { text: "日付", value: "dateText", align: "start"},
        { text: "グループ名", value: "groupName", align: "start"},
        { text: "イベント名", value: "eventName", align: "start"},
        { text: "休日", value: "closeKbn" },
      ],
      events : [],
      isEventsLoding: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    await this.reload();
  },
  methods: {
    //休日フラグ名称
    getcloseKbn(closeFlg){
      if (closeFlg) {
        var rtn = "休日"
      }
      else  {
        rtn = ""
      }
      return rtn;
    },
    // ページ再読込
    async reload() {
      this.events = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isEventsLoding = true;
      schoolAidHomeLogined({funcName:"loadEventList"}).then((events) => {
        var ret = [];
        events.data.forEach((event) => {
          console.log("eventList",event) ;
          const kyujitu=this.getcloseKbn(event.closeFlg) ;
          var row = {
            dateText:event.dateText,
            groupName : event.groupName,
            eventName : event.eventName,
            closeKbn : kyujitu ,
            groupId : event.groupId,
        };
          ret.push(row);
        });
        this.events = ret;
        this.isEventsLoding = false;
      });
    },
  },
};
</script>