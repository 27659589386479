<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6">
          連絡編集
        </v-card-title>
        
        <v-row dense>
          <v-col dense cols="2" justify="center">
            <div class="mx-10 mt-0">
              対象グループ
            </div>
          </v-col>
          <v-col dense cols="8" justify="center">
            <div class="mx-10 mt-0">
              {{ groupName }}
            </div>
          </v-col>
          <v-col dense cols="2" justify="center">
            <v-btn color="accent" class="ma-2" @click="onClickCopyContact">
              コピー
            </v-btn>
          </v-col>
        </v-row>
        <ValidationProvider name="ユーザー区分" rules="required|checkboxRequired" v-slot="{ errors }">
          <div class="mx-10 mt-10">
            <div>ユーザー区分選択</div>
            <v-layout wrap>
                <v-flex v-for="userKbn in userKbns" :key="userKbn.code" >
                  <v-card-actions>
                    <v-checkbox v-model="selectedUserKbn"
                      :label="userKbn.name"
                      :value="userKbn.code"
                      :disabled="!userKbn.isActive"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-card-actions>
                </v-flex>
            </v-layout>
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider name="連絡タイトル" rules="required|max:50" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-text-field
            v-model="title"
            label="連絡タイトル"
            placeholder=""
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>
      <ValidationProvider name="連絡本文" rules="required|max:3000" v-slot="{ errors }">
        <div class="mx-10 mt-0">
          <v-textarea
            v-model="body"
            label="連絡本文"
            placeholder=""
            outlined
            dense
            hide-details="auto"
          ></v-textarea>
        </div> 
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>

      <ValidationProvider name="公開開始日" rules="required" v-slot="{ errors }">
        <div class="mx-10 mt-0"> 
          <v-text-field
            v-model="openDate"
            label="公開開始日"
            type="datetime-local"
            placeholder=""
            outlined
            dense
            :disabled="lockOpenDateFlg"
            hide-details="auto"
            ></v-text-field>
        </div>
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>

      <ValidationProvider name="公開終了日" rules="required|datehourAfter:@公開開始日" v-slot="{ errors }">
        <div class="mx-10 mt-0"> 
          <v-text-field
            v-model="closeDate"
            label="公開終了日"
            placeholder=""
            type="datetime-local"
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
        </div>
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>
      <div class="mx-10 mt-0">
        <v-row>
          <v-col cols="auto">
            <v-card-actions>
              <v-checkbox class="mt-0" v-model="clearFlg" label="既読をクリアする" hide-details="auto" />
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions>
              <v-checkbox class="mt-0" v-model="commentFlg" :disabled="true" label="コメントあり" hide-details="auto" />
            </v-card-actions>
          </v-col>
        </v-row>
      </div>
      <v-card-text justify="center">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  ファイル名
                </th>
                <th class="text-right">
                  削除
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in viewfileList"
                :key="item.attachmentId"
              >
                <td class="text-left"><a @click="downloadItem(item)" class="text-decoration-none">{{ item.fileName }}</a></td>
                <td class="text-right">
                  <v-row class="justify-end">
                    <v-checkbox
                      v-model="selectData"
                      :value="item.attachmentId"
                      :id="item.attachmentId"
                      color="primary"
                      hide-details="auto"
                    />
                </v-row>
                </td>     
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <dashboard :uppy="uppy" :props="{
            theme: 'light',
            inline: true,
            width: 3000,
            height: 350,
            disableThumbnailGenerator:true,
            showRemoveButtonAfterComplete:true,
            proudlyDisplayPoweredByUppy:false,
          }" />
          ※添付ファイルは(容量：50MB、個数：10)が上限となっております
      </v-card-text>
      <v-card-actions>
        <v-btn color="grey" class="mr-auto mt-0 black--text" @click="canselContactEdit"  width="45%">戻る</v-btn>
        <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveContactEdit" :disabled="invalid" width="45%">更新</v-btn>
      </v-card-actions>  
    </v-card>
   </ValidationObserver>
  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import { saveAs } from 'file-saver';

import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  props: ['id'],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.fileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.fileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      selectedUserKbn: [],
      userKbns: [
        { code: "0", name: "生徒" , isActive: true },
        { code: "1", name: "保護者" , isActive: true },
        { code: "8", name: "教員" , isActive: true },
      ],
      groupId: "",
      groupName: "",
      title: "",
      body: "",
      openDate: "" ,
      closeDate : "",
      viewfileList: [],
      fileList: new Set(),
      selectData: [],
      clearFlg: false,
      commentFlg: false,
      lockOpenDateFlg: false,
    };
  },
  async created() {
    // ローディング開始
    this.$root.loading = true;

    const data = {
      funcName: "loadContactEdit",
      contactId: this.id
    };

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
    await schoolAidHomeLogined(data).then((res) => {
      this.groupId = res.data.groupId;
      this.groupName = res.data.groupName;
      this.selectedUserKbn = res.data.selectedUserKbn;
      this.title = res.data.title;
      this.body = res.data.body;
      this.openDate = res.data.openDate;
      this.closeDate = res.data.closeDate;
      this.viewfileList = res.data.fileList;
      this.commentFlg = res.data.commentFlg;
      this.lockOpenDateFlg = res.data.lockOpenDateFlg;
      // ユーザー区分の削除抑制
      for (const code of res.data.selectedUserKbn) {
        for (const userKbn of this.userKbns) {
          if(code == userKbn.code){
            userKbn.isActive = false;
          }
        }
      }

      // ローディング終了
      this.$root.loading = false;
    }).catch((e) => {
      console.log(e.message);
      this.$root.loading = false;
    });
  },
  methods: {
    // 連絡情報編集
    async saveContactEdit() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveContactEdit",
        contactId: this.id,
        selectedUserKbn: this.selectedUserKbn, 
        title: this.title,
        body: this.body,
        openDate: this.openDate,
        closeDate: this.closeDate,
        delFileList : this.selectData,
        fileList : Array.from(this.fileList),
        clearFlg: this.clearFlg,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      this.$router.push({
        name: "ContactList",
      });
    },
    canselContactEdit(){
      this.$router.push({
        name: "ContactList",
      });
    },
    async downloadItem(item){
      saveAs(item.url, item.fileName);
    },
    // 連絡コピー
    onClickCopyContact() {
      this.$router.push({
        name: "ContactRegist",
        params: {
          groupName:this.groupName,
          groupId:this.groupId,
          paramUserKbns:this.selectedUserKbn,
          paramTitle:this.title,
          paramBody:this.body,
          paramOpenDate:this.openDate,
          paramCloseDate:this.closeDate,
        }
      });
    },
  },
};
</script>