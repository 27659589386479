<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6">グループ編集</v-card-title>
        <ValidationProvider name="グループ名" rules="required|max:50" v-slot="{ errors }">
          <div class="mx-10" >
            <v-text-field
              v-model="viewGroupName"
              label="グループ名"
              placeholder=""
              outlined
              dense
              hide-details="auto"
              ></v-text-field>
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>

        <v-card-actions>
          <v-btn color="grey" class="mx-auto black--text" @click="onClickGroupList" width="49%">戻る</v-btn>
          <v-btn color="accent" class="mx-auto black--text" @click="groupsave" :disabled="invalid"  width="49%">更新</v-btn>
        </v-card-actions>  
      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['groupName', 'groupId'],
  data() {
    return {
      viewGroupId : "",
      viewGroupName : "",
      beforeGroupName : "",
    };
  },
  // 初期表示時データ読み込み
  async created() {
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

    this.isListLoding = true;
    await schoolAidHomeLogined({funcName:"loadGroupEdit"}).then((res) => {
      console.log("res", res);
      if (res.data === "success") {
        this.viewGroupId = this.groupId;
        this.viewGroupName = this.groupName;
        this.beforeGroupName = this.groupName;
      }
      this.isListLoding = false;
    });
  },

  methods: {
    async groupsave() {
      if(this.viewGroupName == this.beforeGroupName) {
        alert("グループ名は変更されていません。");
        return;
      }
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1"; 
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var data = {
        funcName: "saveGroupEdit",
        groupId : this.viewGroupId,
        groupName: this.viewGroupName,
      };
      
      var ret = "";
      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        ret = res.data;
      });

      if(ret == "success"){
        alert("登録しました。");
        this.$router.push({
          name: "GroupList",
        });
      } else {
        alert("同一グループ名は既に存在します");
      }
    },

    onClickGroupList() {
      this.$router.push({
        name: "GroupList",
    })},
  },
};
</script>