<template>
    <Doughnut :data="param_data" :options="options" />
</template>

<script lang="ts">
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale } from 'chart.js'
import { Doughnut } from 'vue-chartjs'
  
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale)
  
export default {
  name: 'DoughnutChart',
  components: {
    Doughnut
  },
  props: ['param_data'],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
              position: 'right',
          }
        }
      }
    }
  },
  // 初期表示時データ読み込み
  async created() {
  }
}
</script>