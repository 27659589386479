<template>
  <v-container fluid>
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="374"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6">
          個人設定
        </v-card-title>
        <div class="d-flex justify-left pl-2 py-0 mt-6">
          認証済メールアドレス
        </div>
        <br>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row align-content="center">
            <v-col cols="10">
              <v-text-field
                v-model="mailAddress"
                label="メイン"
                placeholder=""
                outlined
                dense
                disabled="disabled"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="justify-left">
              <div class="deleteMailAddress">
                <v-icon
                  v-if="delIconMain"
                  large
                  color="red"
                  @click="deleteMailAddress('0')"
                >mdi-close-circle-outline
              </v-icon></div>
            </v-col>
          </v-row>
          <div class="text-danger">{{ error }}</div>

          <v-row align-content="center" v-if="mailAddressAuthFlg">
            <v-col cols="10">
              <v-text-field
                v-model="mailAddress2"
                label="サブ"
                placeholder=""
                outlined
                dense
                disabled="disabled"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="justify-left">
              <div class="deleteMailAddress">
                <v-icon
                  v-if="delIconSub"
                  large
                  color="red"
                  @click="deleteMailAddress('1')"
                >mdi-close-circle-outline
              </v-icon></div>
            </v-col>
          </v-row>
          <div class="text-danger">{{ error }}</div>
        </v-card-text>
      </v-card>
    <ValidationObserver ref="obs2" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="374"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6">
          メールアドレス認証
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <div class="text-center">
            メールアドレス認証とは、
            メールアドレスの所有者を確認（ご本人様確認）するためのものです。
            入力頂いたメールアドレスにメールを送信いたします。
            30分以内に届いたメールを開封して頂き、メール内のリンクをクリックしてください。
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column">
          <ValidationProvider name="メイン・サブ選択" rules="required" v-slot="{ errors }" :skipIfEmpty="false">
            <v-row justify="center">
              <v-radio-group row v-if="mailAddressAuthFlg" v-model="selectedMainSubKbn">
                <v-radio label="メイン" :value="0" class="mr-10"></v-radio>
                <v-radio label="サブ"   :value="1"></v-radio>
              </v-radio-group>
            </v-row>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>
          <ValidationProvider name="送信先メールアドレス" rules="required|email" v-slot="{ errors }" >
            <v-text-field
              v-model="destinationMailAddress"
              label="送信先メールアドレス"
              placeholder=""
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>
          <div class="text-danger">{{ error }}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="accent" class="black--text" @click="sendEmailAuth" :disabled="invalid" width="100%">
            確認メール送信
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      mailAddress: "",
      mailAddress2: "",
      destinationMailAddress: "",
      error: "",
      mailAddressAuthFlg : false,
      mainSubKbn:"0",
      selectedMainSubKbn: 0,
      mainSubList: [
        { label: "メイン", mainSubKbn: 0 },
        { label: "サブ", mainSubKbn: 1 }
      ],
      delIconMain: false,
      delIconSub:  false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // ローディング開始
    this.$root.loading = true;
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    await schoolAidHomeLogined({funcName:"loadProfileSetting"}).then(res => {
      this.mailAddress = res.data.mailAddress;
      this.mailAddress2 = res.data.mailAddress2;
      this.mailAddressAuthFlg = res.data.mailAddressAuthFlg;

      if (this.mailAddressAuthFlg) {
        // 認証済みの場合サブを初期値
        this.selectedMainSubKbn = 1;
      }

      // 削除アイコン制御
      this.editDelIcon();
    });
    // ローディング終了
    this.$root.loading = false;
  },
  methods: {
    // 確認メール送信
    async sendEmailAuth() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

      var data = {
        funcName: "sendEmailAuthentication",
        mailAddress: this.destinationMailAddress,
        mainSubKbn: this.selectedMainSubKbn,
      };

      await schoolAidHomeLogined(data).then(() => {
        // ローディング非表示
        this.$root.loading = false;
        alert("確認メールを送信しました。");
      }).catch((e) => {
        this.error = e.message;
        this.$root.loading = false;
      });
    },

    // メールアドレス削除
    async deleteMailAddress(mainSubKbn) {
      var msg = "";
      if (mainSubKbn == "0") {
        msg = "メールアドレス（メイン）設定を削除してよろしいですか？\r\nパスワードがアカウント通知書記載のものに初期化されます。";
      }
      if (mainSubKbn == "1") {
        msg = "メールアドレス（サブ）設定を削除してよろしいですか？";
      }
      var result = confirm(msg);
      if (!result) {
        return;
      }

      // ローディング表示
      this.$root.loading = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

      var data = {
        funcName: "deleteMailAddress",
        mainSubKbn: mainSubKbn,
      };

      await schoolAidHomeLogined(data).then((res) => {
        if (res.data == "success") {
          // 表示設定
          if (mainSubKbn == "0") {
            this.mailAddress = "";
            this.mailAddressAuthFlg = false;
          } else {
            this.mailAddress2 = "";
          }

          // 削除アイコン制御
          this.editDelIcon();

          alert("メール設定を削除しました。");
        }
        // ローディング非表示
        this.$root.loading = false;
      }).catch((e) => {
        this.error = e.message;
        this.$root.loading = false;
      });
    },

    // 削除ボタン制御
    async editDelIcon() {
      if (this.mailAddressAuthFlg) {
        // 認証済
        if (this.mailAddress2) {
          this.delIconMain = false;
          this.delIconSub = true;
        } else {
          this.delIconMain = true;
          this.delIconSub = false;
        }
      } else {
        // 未認証
        if (this.mailAddress) {
          this.delIconMain = true;
          this.delIconSub = false;
        } else {
          this.delIconMain = false;
          this.delIconSub = false;
        }
      }
    },
  },
};
</script>