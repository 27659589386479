<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack">前の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward">次の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="10" md="10" lg="10" xl="10">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row dense>
            <v-col cols="8">
              <div align="center">
                {{ yyyymmddDisp }}の早退登録({{ studentName }})
              </div>
            </v-col>
            <v-col cols="4">
              <div align="right">
                <v-btn color="grey" small class="black--text" @click="calendar">日付選択</v-btn>

              </div>
            </v-col>
          </v-row>
          <Datepicker
            ref="picker"
            :monday-first="true"
            wrapper-class="calender-text-box-wrapper"
            input-class="calender-text-box"
            placeholder=""
            :language="language"
            @selected=dateSelected />
        </v-card>
      </v-col>
    </v-row>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="374"
        color="#fff"
      >
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="早退事由" rules="required|max:50" v-slot="{ errors }">
                <v-text-field
                  v-model="leavingReason"
                  label="早退事由"
                  placeholder="早退事由"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="早退事由(補足)" rules="max:50" v-slot="{ errors }">
                <v-text-field
                  v-model="leavingReasonEtc"
                  label="早退事由(補足)"
                  placeholder="早退事由(補足)"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider
                name="保護者への連絡"
                rules="required"
                v-slot="{ errors }"
                :skipIfEmpty="false"
              >
                <v-select
                  label="保護者への連絡(保護者側へは非通知)"
                  item-text="label"
                  item-value="statusKbn"
                  v-model="selectedComStatus"
                  :items="comStatus"
                  outlined
                  hide-details="auto"
                ></v-select>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="leavingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider name="下校開始予定時刻" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="leavingTime"
                    label="下校開始予定時刻"
                    placeholder="下校開始予定時刻"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                    <p class="error">{{ errors[0] }}</p>
                  </ValidationProvider>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="leavingTime"
                  :allowed-hours="allowedHours"
                  :allowed-minutes="allowedStep"
                  full-width
                  @click:minute="$refs.menu.save(leavingTime)"
                  ></v-time-picker>
                </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <v-checkbox
                class="mt-0"
                v-model="cancelFlg"
                label="早退予定の取り消し"
                hide-details="auto" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="コメント" rules="max:150" v-slot="{ errors }">
                <v-text-field
                  v-model="comment"
                  label="コメント"
                  placeholder="コメント"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <v-checkbox class="mt-0" :disabled="!parentMailAuthFlg" v-model="leavingMailFlg" label="保護者へ通知する" hide-details="auto" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="メールタイトル" rules="max:30" v-slot="{ errors }">
                <p class="error">{{ errors[0] }}</p>
                <div class="mt-n5">
                  <v-text-field class="mb-3"
                  v-model="mailTitle"
                  label="メールタイトル"
                  :disabled="!leavingMailFlg"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field></div>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" class="black--text" @click="toBackPage(groupName,groupId)" width="49%">
            戻る
          </v-btn>
          <v-btn color="accent" class="black--text" @click="sotaiSave(groupName,groupId)"
           :disabled="pastLockFlg ? pastLockFlg : invalid" width="49%">
            更新
          </v-btn>
        </v-card-actions>
        <v-card
          class="d-flex justify-center flex-column mx-auto my-0 flat"
          width="1200"
          color="#fff"
        >
          <v-list>
            <v-subheader>【履歴】</v-subheader>
            <v-list-item-group  v-if="history.length">
              <v-list-item v-for="row in history" :key="row.postDateTime">
                <v-list-item-content>
                  <v-list-item-subtitle>更新日時：{{ row.postDate }}</v-list-item-subtitle>
                  <v-list-item-subtitle>更新者：{{ row.userNm }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.leavingReason">早退事由：{{ row.leavingReason }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.leavingReasonEtc">早退事由(補足)：{{ row.leavingReasonEtc }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.comStatusName">保護者への連絡：{{ row.comStatusName }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.leavingTime">予定開始予定時刻：{{ row.leavingTime }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.comment">コメント：{{ row.comment }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.userKbn=='8' && row.leavingMailFlg">ﾒｰﾙﾀｲﾄﾙ：{{ row.mailTitle }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group  v-if="!history.length" color="primary">
              <v-list-item>履歴はありません</v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card> 
      </v-card>
    </ValidationObserver>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';

export default {
  props: ['studentId', 'studentName', 'groupName', 'groupId', 'parentMailAuthFlg', 'beforeName', 'param_yyyymmdd'],
  watch: {
    yyyymmdd: async function(newVal, oldVal) {
      console.log(newVal, oldVal)
      await this.reload();
    }
  },
  data() {
    return {
      yyyymmdd: "",
      yyyymmddDisp: "",
      cancelFlg: false,
      leavingReason: "",
      leavingReasonEtc: "",
      selectedComStatus: { label: "連絡済(お迎えなし)", statusKbn: 4 }, //初期値
      comStatus: [
        { label: "連絡済(お迎えなし)", statusKbn: 4 },
        { label: "連絡済(お迎えあり)", statusKbn: 3 },
        { label: "保護者からの連絡", statusKbn: 1 },
        { label: "連絡取れず", statusKbn: 2 },
        { label: "未連絡", statusKbn: 0 },
      ],
      leavingTime: "",
      comment: "",
      mailTitle: "",
      history: "",
      leavingMailFlg: false,
      isListLoding: false,
      language:{
        language: 'Japanese',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        days: ['日', '月', '火', '水', '木', '金', '土'],
        yearSuffix: '年',
        ymd: true,
      },
      pastLockFlg: false,

      menu2: false,
      modal2: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    this.leavingMailFlg = this.parentMailAuthFlg;
    await this.reload();
  },
  methods: {
    // タイムピッカーの入力制限設定
    allowedHours: v => 5 < v && v < 24,
    allowedStep: m => m % 10 === 0,
    // データピッカー開閉
    calendar() {
        if(!this.$refs.picker.isOpen) {
            this.$refs.picker.$el.querySelector("input").focus();
            this.$refs.picker.showCalendar();
        } else {
            this.$refs.picker.close();
        }
    },
    // データピッカー日付選択
    dateSelected(selectedDate) {
        this.yyyymmdd = format(selectedDate, 'yyyy-MM-dd', { locale: ja });
        this.yyyymmddDisp = format(selectedDate, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 前の日へボタン
    toBack() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() - 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 次の日へボタン
    toForward() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() + 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // ページ再読込
    async reload() {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      const data = {
        funcName: "loadLeavingRegist",
        yyyymmdd: this.yyyymmdd,
        studentId: this.studentId
      };
      await schoolAidHomeLogined(data).then((res) => {
        this.leavingReason = res.data.leavingReason;
        this.leavingReasonEtc = res.data.leavingReasonEtc;
        this.selectedComStatus = res.data.comStatusKbn;
        this.leavingTime = res.data.leavingTime;
        this.cancelFlg = res.data.cancelFlg;
        this.history = res.data.history;
        this.pastLockFlg = res.data.pastLockFlg;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
      // ローディング終了
      this.$root.loading = false;
    },
    // 早退設定保存
    async sotaiSave(groupName,groupId) {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var data = {
        funcName: "saveLeavingRegist",
        yyyymmdd: this.yyyymmdd,
        studentId: this.studentId,  
        leavingReason: this.leavingReason,
        leavingReasonEtc: this.leavingReasonEtc,
        comStatusKbn: this.selectedComStatus,
        leavingTime: this.leavingTime,
        cancelFlg: this.cancelFlg,
        comment: this.comment,
        leavingMailFlg: this.leavingMailFlg,
        mailTitle : this.mailTitle
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        alert("登録しました");
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
      var params = {};
      if (this.beforeName) {
        params = {
          name: this.beforeName,
          params: {
            param_yyyymmdd: this.yyyymmdd,
          }
        }
      } else {
        params = {
          name: "UserList",
          params: {
            groupName: groupName,
            groupId: groupId,
            param_yyyymmdd: this.yyyymmdd,
          }
        }
      }
      this.$router.push(params);
    },
    toBackPage(groupName,groupId){
      console.log("戻る", groupId, groupName);
      var params = {};
      if (this.beforeName) {
        params = {
          name: this.beforeName,
          params: {
            param_yyyymmdd: this.yyyymmdd,
          }
        }
      } else {
        params = {
          name: "UserList",
          params: {
            groupName: groupName,
            groupId: groupId,
            param_yyyymmdd: this.yyyymmdd,
          }
        }
      }
      this.$router.push(params);
    }
  },
};
</script>


<style scoped>
.wrap-text{
  white-space: normal;
}
</style>