<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="374"
        color="#fff"
      >
        <img :src="require('@/assets/logo.svg')" alt="Logo" class="logo" />
        <v-card-title class="d-flex justify-center pa-0 mt-6"
          >パスワード変更</v-card-title
        >
        <v-card-text class="d-flex justify-center flex-column">
          <div class="text-center">
            パスワード変更を行う場合は、メールアドレスの登録が必要となります。
            ログイン後【設定】より、メールアドレス登録をお願いいたします。未登録の場合は学校より配布された資料をご確認ください。
          </div>
          <ValidationProvider
            name="ログインＩＤ"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="uid"
              label="ログインＩＤ"
              placeholder="ログインＩＤ"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
            <p class="error">{{ errors[0] }}</p>
            <p class="error">{{ error }}</p>
          </ValidationProvider>
          <div class="text-center">
            <v-btn class="accent" @click="passwordChange" :disabled="invalid">メール送信</v-btn>
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column">
          <a style="color:#000;" href="#" @click.prevent.stop="toLoginPage()">ログイン画面へ</a>
        </v-card-text>
      </v-card>
    </ValidationObserver>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  data() {
    return {
      uid: "",
      error: "",
    };
  },
  methods: {
    // パスワード変更メールの送信
    async passwordChange() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");

      var data = {
        funcName: "sendForgetPassword",
        uid: this.uid,
      };

      await schoolAidHomePublic(data).then(() => {
        // ローディング非表示
        this.$root.loading = false;
        alert("パスワード変更用メールを送信しました。");
      }).catch((e) => {
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // ログイン画面へ
    toLoginPage() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
.logo {
  display: block;
  position: relative;
  overflow: hidden;
}
</style>