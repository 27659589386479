<template>
  <v-container fluid>
    <ValidationObserver ref="obs" tag="form" v-slot="{ invalid }">
      <v-row>
        <v-col cols="12" justify="center">
          <v-card class="mx-auto text-center" color="blue-grey" dark>
            イベント設定({{ groupName }})
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" justify="center">
          <v-data-table
            :headers="headers"
            :items="events"
            :items-per-page="30"
            :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
            :loading="isListLoding" loading-text="読込中"
          >
            <template #[`item.eventName`]="{ item }">
              <ValidationProvider
                name="イベント名"
                rules="max:50"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="item.eventName"
                  solo
                  flat
                  hide-details
                  outlined
                  dense
                />
                <p class="error mb-0">{{ errors[0] }}</p>
              </ValidationProvider>
            </template>    
            <!-- チェックボックス -->
            <template  #[`item.closeFlg`]="{ item }">
            <v-checkbox
                v-if="item.canCloseFlg"
                v-model="item.closeFlg"
                hide-details
                color="indigo"
                style="width: 25px" 
                class="pa-0 ma-0 ml-5"
            />
            </template>
        </v-data-table>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-card-actions>
            <v-btn color="grey" class="black--text" @click="onClicktoGroupList">戻る</v-btn>
          </v-card-actions>
        </v-col>
        <v-col cols="6"></v-col>
        <v-col cols="3">
          <v-card-actions class="justify-end">
            <v-btn color="accent" class="black--text" @click="onClicktoEventRegist" :disabled="invalid">更新</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </ValidationObserver>  
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['groupName','groupId'],
  data() {
    return {
      headers: [
        { text: "", value: ""},
        { text: "", value: "" },
        { text: "", value: "" },
      ],
      events: [],
      isListLoding: false,
    };
  },
  async created() {
    // ローディング開始
    
    const data = {
      funcName: "loadEventRegist",
      groupId:this.groupId,
    } ;

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    this.isListLoding = true;
    
    await schoolAidHomeLogined(data).then((events) => {
      var ret = [];
      events.data.forEach((event) => {
        var row = {
          displayDate: event.displayDate,
          eventDate: event.eventDate,
          eventName: event.eventName,
          closeFlg: event.closeFlg,
          canCloseFlg: event.canCloseFlg,
          oldEventName: event.eventName,
          oldCloseFlg: event.closeFlg,
        };
        ret.push(row);
      });
      this.events = ret;

      const date = { text: "日付", value: "displayDate" };
      const eventName = { text: "イベント名", value: "eventName" };
      const holiday = { text: '休み', value: 'closeFlg' };

      const text = '';
      const index = this.headers.findIndex((v) => v.text === text);

      if (this.events[0].canCloseFlg == true) {
        this.headers.splice(index, 3, date, eventName, holiday);
      } else {
        this.headers.splice(index, 2, date, eventName);
      }

      this.isListLoding = false;
      
    });


  },
  methods: {
    // グループ設定画面へ
    onClicktoGroupList() {
      this.$router.push({
        name: "GroupList",
      });
    },
  
    async onClicktoEventRegist() {

      const data=  {
        funcName: "saveEventRegist",
        groupId : this.groupId,
        events : this.events.filter(event => event.eventName !== event.oldEventName || event.closeFlg !== event.oldCloseFlg),
      }

      for (var i = 0; i < data.events.length; i++) {
        if (data.events[i].eventName.length > 50) {
          alert(data.events[i].displayDate + "：イベント名は50字以内で入力してください。");
          return;
        }
      }

      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        if (res.data === "success") {
              alert("保存しました。");
        } else {
              alert("保存に失敗しました");
        }
      });
      this.$router.push({
        name: "GroupList",
      });
    },
  },
};
</script>