<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6">
          アンケート編集
        </v-card-title>
        
        <v-row dense>
          <v-col dense cols="2" justify="center">
            <div class="mx-10 mt-0">
              対象グループ
            </div>
          </v-col>
          <v-col dense cols="8" justify="center">
            <div class="mx-10 mt-0">
              {{ groupName }}
            </div>
          </v-col>
          <v-col dense cols="2" justify="center">
            <v-btn color="accent" class="ma-2" @click="onClickCopySurvey">
              コピー
            </v-btn>
          </v-col>
        </v-row>
        <ValidationProvider name="ユーザー区分" rules="required|checkboxRequired" v-slot="{ errors }">
          <div class="mx-10 mt-10">
            <div>ユーザー区分選択</div>
            <v-layout wrap>
                <v-flex v-for="userKbn in userKbns" :key="userKbn.code" >
                  <v-checkbox v-model="selectedUserKbn"
                    :label="userKbn.name"
                    :value="userKbn.code"
                    :disabled="!userKbn.isActive"
                    hide-details="auto"
                  ></v-checkbox>
                </v-flex>
            </v-layout>
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>
        <ValidationProvider name="アンケートタイトル" rules="required|max:50" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-text-field
            v-model="title"
            label="アンケートタイトル"
            placeholder=""
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>
      <ValidationProvider name="アンケート本文" rules="required|max:3000" v-slot="{ errors }">
        <div class="mx-10 mt-0">
          <v-textarea
            v-model="body"
            label="アンケート本文"
            placeholder=""
            outlined
            dense
            hide-details="auto"
          ></v-textarea>
        </div> 
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>

      <ValidationProvider name="公開開始日" rules="required" v-slot="{ errors }">
        <div class="mx-10 mt-0"> 
          <v-text-field
            v-model="openDate"
            label="公開開始日"
            type="datetime-local"
            placeholder=""
            outlined
            dense
            :disabled="lockOpenDateFlg"
            hide-details="auto"
            ></v-text-field>
        </div>
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>

      <ValidationProvider name="公開終了日" rules="required|datehourAfter:@公開開始日" v-slot="{ errors }">
        <div class="mx-10 mt-0"> 
          <v-text-field
            v-model="closeDate"
            label="公開終了日"
            placeholder=""
            type="datetime-local"
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
        </div>
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>
      <div class="mx-10 mt-0 mb-0">
        <v-card-actions>
          <v-checkbox class="mt-0" v-model="anonymousFlg" label="匿名でアンケート依頼する" disabled/>
        </v-card-actions>
      </div>
      <div class="mx-10 mt-0">
        <v-row>
          <v-col cols="auto">
            <v-card-actions>
              <v-checkbox class="mt-0" v-model="clearFlg" label="既存の回答をクリアする" hide-details="auto" />
            </v-card-actions>
          </v-col>
        </v-row>
      </div>
      <div class="mx-10 mt-8 mb-5">
        <div>アンケート項目（非公開かつ回答をクリアしてあれば編集可能です）</div>
      </div>

      <!-- テキスト回答のアンケート -->
      <v-card v-for="(question, index) in questionList" :key="question.id" class="mx-10 mt-0 mb-4">
        <div v-if="question.type == '0'">
          <div class="mx-5 mt-2">
            <v-row>
              <v-col cols="2">
                <div>{{ (index + 1) }}問目</div>
              </v-col>
              <v-col cols="2">
                <div>{{ question.typeName }}</div>
              </v-col>
              <v-col cols="7">
                <v-checkbox
                v-model="question.required"
                label="回答必須に設定する"
                class="my-0"
                dense
                hide-details
                :disabled="questionDisabled"
                ></v-checkbox>
              </v-col>
              <v-col cols="1" class="">
                <v-btn
                small
                class="ma-2 info"
                @click="removeQuestion(question.id)"
                :disabled="questionDisabled"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
          </v-row>
            <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
              <v-row class="mb-0 mt-2">
                <v-col cols="12" class="py-0">
                  <v-text-field
                  v-model="question.content"
                  label="質問"
                  placeholder=""
                  dense
                  hide-details
                  :disabled="questionDisabled"
                  ></v-text-field>
                </v-col>
              </v-row>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
          <div class="mx-5 mt-0">
            <ValidationProvider name="回答文字数制限" rules="required|numeric" v-slot="{ errors }">
              <v-row>
                <v-col cols="2" class="pr-0 pb-0">
                  <v-text-field
                  v-model="question.charLimit"
                  label="回答文字数制限"
                  outlined
                  dense
                  hide-details
                  :disabled="questionDisabled"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="pl-1 pb-0">
                  <div>文字</div>
                </v-col>
                <v-col cols="9" class="pb-0">
                  <v-text-field
                  placeholder="回答入力欄"
                  outlined
                  disabled
                  dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
        </div>

        <!-- チェックボックス回答のアンケート -->
        <div class="mx-5 mt-2" v-if="question.type=='1'">
          <v-row>
            <v-col cols="2">
                <div>{{ (index + 1) }}問目</div>
              </v-col>
              <v-col cols="2">
                <div>{{ question.typeName }}</div>
              </v-col>
              <v-col cols="7">
              <v-checkbox
              v-model="question.required"
              label="回答必須に設定する"
              class="my-0"
              dense
              hide-details
              :disabled="questionDisabled"
              ></v-checkbox>
            </v-col>
            <v-col cols="1" class="">
              <v-btn
              small
              class="ma-2 info"
              @click="removeQuestion(question.id)"
              :disabled="questionDisabled"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
            <v-row class="mb-0 mt-2">
              <v-col cols="12" class="py-0">
                <v-text-field
                v-model="question.content"
                label="質問"
                placeholder=""
                dense
                hide-details
                :disabled="questionDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>
          <v-row v-for="checkOption in question.option" :key="checkOption.id" class="my-0">
            <v-col cols="1" class="py-0">
              <v-checkbox
              placeholder=""
              dense
              hide-details
              readonly
              ></v-checkbox>
            </v-col>
            <v-col cols="10" class="py-0">
              <ValidationProvider name="選択肢" rules="required|max:50" v-slot="{ errors }">
                <v-text-field
                v-model="checkOption.title"
                label="選択肢"
                outlined
                dense
                hide-details
                :disabled="questionDisabled"
                ></v-text-field>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
            <v-col cols="1" class="py-0">
              <v-btn
              small
              class="ma-2 warning"
              @click="removeCheckOption(question.id, checkOption.id)"
              :disabled="questionDisabled"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn 
              small 
              class="ma-2 primary" 
              @click="addCheckOption(question.id)"
              :disabled="questionDisabled"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- ラジオボタン回答のアンケート -->
        <div class="mx-5 mt-2" v-if="question.type=='2'">
          <v-row>
            <v-col cols="2">
              <div>{{ (index + 1) }}問目</div>
            </v-col>
            <v-col cols="2">
              <div>{{ question.typeName }}</div>
            </v-col>
            <v-col cols="7">
              <v-checkbox
              v-model="question.required"
              label="回答必須に設定する"
              class="my-0"
              dense
              hide-details
              :disabled="questionDisabled"
              ></v-checkbox>
            </v-col>
            <v-col cols="1" class="">
              <v-btn
              small
              class="ma-2 info"
              @click="removeQuestion(question.id)"
              :disabled="questionDisabled"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
            <v-row class="mb-0 mt-2">
              <v-col cols="12" class="py-0">
                <v-text-field
                v-model="question.content"
                label="質問"
                placeholder=""
                dense
                hide-details
                :disabled="questionDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>
          <v-row v-for="radioOption in question.option" :key="radioOption.id" class="my-0">
            <v-col cols="1" class="py-0">
              <v-radio
              dense
              hide-details
              readonly
              ></v-radio>
            </v-col>
            <v-col cols="10" class="py-0">
              <ValidationProvider name="選択肢" rules="required|max:50" v-slot="{ errors }">
                <v-text-field
                v-model="radioOption.title"
                label="選択肢"
                outlined
                dense
                hide-details
                :disabled="questionDisabled"
                ></v-text-field>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
            <v-col cols="1" class="py-0">
              <v-btn
              small
              class="ma-2 warning"
              @click="removeRadioOption(question.id, radioOption.id)"
              :disabled="questionDisabled"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn 
              small 
              class="ma-2 primary" 
              @click="addRadioOption(question.id)"
              :disabled="questionDisabled"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>

      <v-row class="mx-10 my-5">
        <v-btn
        small
        class="ma-2 primary"
        @click="addInputText()"
        :disabled="questionDisabled"
        >
        <div>＋テキスト回答</div>
        </v-btn>
        <v-btn
          small
          class="ma-2 primary"
          @click="addInputCheck()"
          :disabled="questionDisabled"
        >
        <div>＋チェックボックス回答</div>
        </v-btn>
        <v-btn
          small
          class="ma-2 primary"
          @click="addInputRadio()"
          :disabled="questionDisabled"
        >
        <div>＋ラジオボタン回答</div>
        </v-btn>
      </v-row>
      <v-card-text justify="center">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  ファイル名
                </th>
                <th class="text-right">
                  削除
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in viewfileList"
                :key="item.attachmentId"
              >
                <td class="text-left"><a @click="downloadItem(item)" class="text-decoration-none">{{ item.fileName }}</a></td>
                <td class="text-right">
                  <v-row class="justify-end">
                    <v-checkbox
                      v-model="selectData"
                      :value="item.attachmentId"
                      :id="item.attachmentId"
                      color="primary"
                      hide-details="auto"
                    />
                </v-row>
                </td>     
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <dashboard :uppy="uppy" :props="{
            theme: 'light',
            inline: true,
            width: 3000,
            height: 350,
            disableThumbnailGenerator:true,
            showRemoveButtonAfterComplete:true,
            proudlyDisplayPoweredByUppy:false,
          }" />
          ※添付ファイルは(容量：50MB、個数：10)が上限となっております
      </v-card-text>
      <v-card-actions>
        <v-btn color="grey" class="mr-auto mt-0 black--text" @click="canselSurveyEdit"  width="45%">戻る</v-btn>
        <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveSurveyEdit" :disabled="invalid" width="45%">更新</v-btn>
      </v-card-actions>  
    </v-card>
   </ValidationObserver>
  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import { saveAs } from 'file-saver';
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  props: ['id'],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.fileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.fileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      selectedUserKbn: [],
      userKbns: [
        { code: "0", name: "生徒" , isActive: true },
        { code: "1", name: "保護者" , isActive: true },
        { code: "8", name: "教員" , isActive: true },
      ],
      groupId: "",
      groupName: "",
      title: "",
      body: "",
      openDate: "" ,
      closeDate : "",
      viewfileList: [],
      fileList: new Set(),
      selectData: [],
      clearFlg: false,
      questionList: [],
      questionDisabled: false,
      lockOpenDateFlg: false,
      anonymousFlg: false,
    };
  },
  async created() {
    // ローディング開始
    this.$root.loading = true;

    const data = {
      funcName: "loadSurveyEdit",
      surveyId: this.id
    };

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
    await schoolAidHomeLogined(data).then((res) => {
      this.groupId = res.data.groupId;
      this.groupName = res.data.groupName;
      this.selectedUserKbn = res.data.selectedUserKbn;
      this.title = res.data.title;
      this.body = res.data.body;
      this.openDate = res.data.openDate;
      this.closeDate = res.data.closeDate;
      this.viewfileList = res.data.fileList;
      this.questionList = res.data.questionList;
      this.questionDisabled = res.data.questionDisabled;
      this.lockOpenDateFlg = res.data.lockOpenDateFlg;
      this.anonymousFlg = res.data.anonymousFlg;
      // ユーザー区分の削除抑制
      for (const code of res.data.selectedUserKbn) {
        for (const userKbn of this.userKbns) {
          if(code == userKbn.code){
            userKbn.isActive = false;
          }
        }
      }
      // ローディング終了
      this.$root.loading = false;
    }).catch((e) => {
      console.log(e.message);
      this.$root.loading = false;
    });
  },
  methods: {
    // テキストアンケート追加
    addInputText () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id: createId,
        content: "",
        type: "0",
        typeName: "テキスト回答",
        charLimit: "",
        required: false,
        textFlg: true,
        checkFlg: false,
        radioFlg: false,
        option: [],
      };
      this.questionList.push(row);
    },
  
    // チェックボックスアンケート追加
    addInputCheck () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id: createId,
        content: "",
        type: "1",
        typeName: "チェックボックス回答",
        charLimit: null,
        required: false,
        textFlg: false,
        checkFlg: true,
        radioFlg: false,
        option: [],
      };
      this.questionList.push(row);
    },
  
    // ラジオボタンアンケート追加
    addInputRadio () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id : createId,
        content: "",
        type: "2",
        typeName: "ラジオボタン回答",
        charLimit: null,
        required: false,
        textFlg: false,
        checkFlg: false,
        radioFlg: true,
        option: [],
      };
      this.questionList.push(row);
    },
  
    // アンケート削除
    removeQuestion (id) {
      this.questionList = this.questionList.filter(function(item) {
        return item.id != id;
      });
    },
  
    // チェックボックス選択肢追加
    addCheckOption (id) {
      var index = this.questionList.findIndex(item => item.id == id);
      var createId;
      if (this.questionList[index].option.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList[index].option.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id : createId,
        title : "",
      };
      this.questionList[index].option.push(row);
    },
  
    // ラジオボタン選択肢追加
    addRadioOption (id) {
      var index = this.questionList.findIndex(item => item.id == id);
      var createId;
      if (this.questionList[index].option.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList[index].option.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id : createId,
        title : "",
      };
      this.questionList[index].option.push(row);
    },
  
    // チェックボックス選択肢削除
    removeCheckOption (questionId, checkId) {
      var index = this.questionList.findIndex(item => item.id == questionId);
      this.questionList[index].option = this.questionList[index].option.filter(function(item) {
        return item.id != checkId;
      });
    },
  
    // ラジオボタン選択肢削除
    removeRadioOption (checkId, radioId) {
      var index = this.questionList.findIndex(item => item.id == checkId);
      this.questionList[index].option = this.questionList[index].option.filter(function(item) {
        return item.id != radioId;
      });
    },

    // アンケート情報編集
    async saveSurveyEdit() {
      for (var i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].type == "1" || this.questionList[i].type == "2") {
          if (this.questionList[i].option.length == 0){
            alert("選択肢を作成してください。");
            return;
          }
        }
      }
      if (this.questionList.length == 0) {
        alert("アンケート項目を作成してください");
        return;
      }
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveSurveyEdit",
        surveyId: this.id,
        selectedUserKbn: this.selectedUserKbn, 
        title: this.title,
        body: this.body,
        openDate: this.openDate,
        closeDate: this.closeDate,
        delFileList : this.selectData,
        fileList : Array.from(this.fileList),
        clearFlg: this.clearFlg,
        questionList : this.questionList,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      this.$router.push({
        name: "SurveyList",
      });
    },
    canselSurveyEdit(){
      this.$router.push({
        name: "SurveyList",
      });
    },
    // アンケートコピー
    onClickCopySurvey() {
      this.$router.push({
        name: "SurveyRegist",
        params: {
          groupName:this.groupName,
          groupId:this.groupId,
          paramUserKbns:this.selectedUserKbn,
          paramTitle:this.title,
          paramBody:this.body,
          paramOpenDate:this.openDate,
          paramCloseDate:this.closeDate,
          paramQuestionList:this.questionList,
        }
      });
    },
    async downloadItem(item){
      saveAs(item.url, item.fileName);
    },
  },
};
</script>