<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6">
          アンケート登録
        </v-card-title>
        
        <v-row dense>
          <v-col cols="9" justify="center">
            <div class="mx-10 mt-0">
              <div>対象グループ</div>
              <v-layout wrap>
                {{ seletctGroupName }}
              </v-layout>
            </div>
          </v-col>
          <v-col cols="2" justify="center">
            <v-btn color="accent" class="ma-2" :disabled="isTargetSelect" @click="openGroupSelectDialog">
              グループ選択
            </v-btn>
          </v-col>
        </v-row>
        <ValidationProvider name="ユーザー区分" rules="required|checkboxRequired" v-slot="{ errors }">
          <div class="mx-10 mt-10">
            <div>ユーザー区分選択</div>
            <v-layout wrap>
                <v-flex v-for="userKbn in userKbns" :key="userKbn.code" >
                  <v-checkbox v-model="selectedUserKbn"
                    :label="userKbn.name"
                    :value="userKbn.code"
                    :disabled="isTargetSelect"
                    hide-details="auto"
                  ></v-checkbox>
                </v-flex>
            </v-layout>
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>
        <ValidationProvider name="アンケートタイトル" rules="required|max:50" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-text-field
            v-model="title"
            label="アンケートタイトル"
            placeholder=""
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
          </div>
          <p class="error">{{ errors[0] }}</p>
        </ValidationProvider>
      <ValidationProvider name="アンケート本文" rules="required|max:3000" v-slot="{ errors }">
        <div class="mx-10 mt-0">
          <v-textarea
            v-model="body"
            label="アンケート本文"
            placeholder=""
            outlined
            dense
            hide-details="auto"
          ></v-textarea>
        </div> 
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>

      <ValidationProvider name="公開開始日" rules="required" v-slot="{ errors }">
        <div class="mx-10 mt-0"> 
          <v-text-field
            v-model="openDate"
            label="公開開始日"
            placeholder=""
            type="datetime-local"
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
        </div>
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>

      <ValidationProvider name="公開終了日" rules="required|datehourAfter:@公開開始日" v-slot="{ errors }">
        <div class="mx-10 mt-0"> 
          <v-text-field
            v-model="closeDate"
            label="公開終了日"
            placeholder=""
            type="datetime-local"
            outlined
            dense
            hide-details="auto"
            ></v-text-field>
        </div>
        <p class="error">{{ errors[0] }}</p>
      </ValidationProvider>

      <div class="mx-10 mt-8 mb-5">
        <div>アンケート項目</div>
      </div>

      <!-- テキスト回答のアンケート -->
      <v-card v-for="(question, index) in questionList" :key="question.id" class="mx-10 mt-0 mb-4">
        <div v-if="question.type == '0'">
          <div class="mx-5 mt-2">
            <v-row>
              <v-col cols="2">
                <div>{{ (index + 1) }}問目</div>
              </v-col>
              <v-col cols="2">
                <div>{{ question.typeName }}</div>
              </v-col>
              <v-col cols="7">
                <v-checkbox
                v-model="question.required"
                label="回答必須に設定する"
                class="my-0"
                dense
                hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="1" class="">
                <v-btn
                small
                class="ma-2 info"
                @click="removeQuestion(question.id)"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
          </v-row>
            <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
              <v-row class="mb-0 mt-2">
                <v-col cols="12" class="py-0">
                  <v-text-field
                  v-model="question.content"
                  label="質問"
                  placeholder=""
                  dense
                  hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
          <div class="mx-5 mt-0">
            <ValidationProvider name="回答文字数制限" rules="required|numeric" v-slot="{ errors }">
              <v-row>
                <v-col cols="2" class="pr-0 pb-0">
                  <v-text-field
                  v-model="question.charLimit"
                  label="回答文字数制限"
                  outlined
                  dense
                  hide-details
                  @blur="formatNum(question)"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="pl-1 pb-0">
                  <div>文字</div>
                </v-col>    
                <v-col cols="9" class="pb-0">
                  <v-text-field
                  placeholder="回答入力欄"
                  outlined
                  disabled
                  dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
        </div>

        <!-- チェックボックス回答のアンケート -->
        <div class="mx-5 mt-2" v-if="question.type == '1'">
          <v-row>
            <v-col cols="2">
              <div>{{ (index + 1) }}問目</div>
            </v-col>
            <v-col cols="2">
              <div>{{ question.typeName }}</div>
            </v-col>
            <v-col cols="7">
              <v-checkbox
              v-model="question.required"
              label="回答必須に設定する"
              class="my-0"
              dense
              hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="1" class="">
              <v-btn
              small
              class="ma-2 info"
              @click="removeQuestion(question.id)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
            <v-row class="mb-0 mt-2">
              <v-col cols="12" class="py-0">
                <v-text-field
                v-model="question.content"
                label="質問"
                placeholder=""
                dense
                hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>
          <v-row v-for="checkOption in question.option" :key="checkOption.id" class="my-0">
            <v-col cols="1" class="py-0">
              <v-checkbox
              placeholder=""
              dense
              hide-details
              readonly
              ></v-checkbox>
            </v-col>
            <v-col cols="10" class="py-0">
              <ValidationProvider name="選択肢" rules="required|max:50" v-slot="{ errors }">
                <v-text-field
                v-model="checkOption.title"
                label="選択肢"
                outlined
                dense
                hide-details
                ></v-text-field>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
            <v-col cols="1" class="py-0">
              <v-btn
              small
              class="ma-2 warning"
              @click="removeCheckOption(question.id, checkOption.id)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn 
              small 
              class="ma-2 primary" 
              @click="addCheckOption(question.id)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- ラジオボタン回答のアンケート -->
        <div class="mx-5 mt-2" v-if="question.type == '2'">
          <v-row>
            <v-col cols="2">
              <div>{{ (index + 1) }}問目</div>
            </v-col>
            <v-col cols="2">
              <div>{{ question.typeName }}</div>
            </v-col>
            <v-col cols="7">
              <v-checkbox
              v-model="question.required"
              label="回答必須に設定する"
              class="my-0"
              dense
              hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="1" class="">
              <v-btn
              small
              class="ma-2 info"
              @click="removeQuestion(question.id)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <ValidationProvider name="質問" rules="required|max:400" v-slot="{ errors }">
            <v-row class="mb-0 mt-2">
              <v-col cols="12" class="py-0">
                <v-text-field
                v-model="question.content"
                label="質問"
                placeholder=""
                dense
                hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <p class="error">{{ errors[0] }}</p>
          </ValidationProvider>
          <v-row v-for="radioOption in question.option" :key="radioOption.id" class="my-0">
            <v-col cols="1" class="py-0">
              <v-radio
              dense
              hide-details
              readonly
              ></v-radio>
            </v-col>
            <v-col cols="10" class="py-0">
              <ValidationProvider name="選択肢" rules="required|max:50" v-slot="{ errors }">
                <v-text-field
                v-model="radioOption.title"
                label="選択肢"
                outlined
                dense
                hide-details
                ></v-text-field>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
            <v-col cols="1" class="py-0">
              <v-btn
              small
              class="ma-2 warning"
              @click="removeRadioOption(question.id, radioOption.id)"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-btn 
              small 
              class="ma-2 primary" 
              @click="addRadioOption(question.id)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>

      <v-row class="mx-10 my-5">
        <v-btn
        small
        class="ma-2 primary"
        @click="addInputText()"
        >
        <div>＋テキスト回答</div>
        </v-btn>
        <v-btn
          small
          class="ma-2 primary"
          @click="addInputCheck()"
        >
        <div>＋チェックボックス回答</div>
        </v-btn>
        <v-btn
          small
          class="ma-2 primary"
          @click="addInputRadio()"
        >
        <div>＋ラジオボタン回答</div>
        </v-btn>
      </v-row>
      <div class="mx-10 mt-0">
        <v-row>
          <v-col cols="auto">
            <v-card-actions>
              <v-checkbox class="mt-0" v-model="mailSendFlg" label="公開開始にあわせてメールを配信する" hide-details="auto" />
            </v-card-actions>
          </v-col>
          <v-col cols="auto">
            <v-card-actions>
              <v-checkbox class="mt-0" v-model="anonymousFlg" label="匿名でアンケート依頼する" hide-details="auto" />
            </v-card-actions>
          </v-col>
        </v-row>
      </div>
      <v-card-text justify="center">
        <dashboard :uppy="uppy" :props="{
            theme: 'light',
            inline: true,
            width: 3000,
            height: 350,
            disableThumbnailGenerator:true,
            showRemoveButtonAfterComplete:true,
            proudlyDisplayPoweredByUppy:false,
          }" />
          ※添付ファイルは(容量：50MB、個数：10)が上限となっております
      </v-card-text>
      <v-card-actions>
        <v-btn color="grey" class="mr-auto mt-0 black--text" @click="canselSurveyRegist"  width="45%">戻る</v-btn>
        <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveSurveyRegist" :disabled="invalid" width="45%">登録</v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
    <!-- グループ選択ダイアログ -->
    <v-dialog
      v-model="groupSelectDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="ml-5">グループ選択</v-card-title>
        <v-card-text>
          <v-radio-group v-model="seletctGroupId"
            :mansdatory="false"
            @change="changeGroupId"
            >
            <v-radio
              v-for="group in groups"
              :key="group.groupId"
              :label="group.groupName"
              :value="group.groupId"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="12" class="ml-1" justify="center">
              <v-btn block color="grey" @click="groupSelectDialog = false">
                閉じる
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Enumerable from "linq";
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";

import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  props: ['groupName','groupId','paramUserKbns','paramTitle','paramBody','paramOpenDate','paramCloseDate','paramQuestionList','paramTargetIds'],
    // ファイルアップロード実装 START-------------------------
    components: {
    Dashboard
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.fileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.fileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      selectedUserKbn: [],
      userKbns: [
        { code: "0", name: "生徒" },
        { code: "1", name: "保護者" },
        { code: "8", name: "教員" },
      ],
      title: "",
      body: "",
      openDate: "" ,
      closeDate : "",
      fileList: new Set(),
      mailSendFlg: true,
      questionList: [],
      groupSelectDialog: false,
      seletctGroupName: "",
      seletctGroupId: "",
      groups: [],
      targetIds: [],
      isTargetSelect: false,
      anonymousFlg: false,
    };
  },
  created() {
    this.defaultDateSet() ;
    if(this.paramUserKbns)this.selectedUserKbn = Array.from(this.paramUserKbns);
    if(this.paramTitle)this.title = this.paramTitle;
    if(this.paramBody)this.body = this.paramBody;
    if(this.paramOpenDate)this.openDate = this.paramOpenDate;
    if(this.paramCloseDate)this.closeDate = this.paramCloseDate;
    if(this.paramQuestionList)this.questionList = this.paramQuestionList;
    if(this.paramTargetIds){
      this.targetIds = this.paramTargetIds;
      this.isTargetSelect = true;
    }
    this.seletctGroupName = this.groupName;
    this.seletctGroupId = this.groupId;
 
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    schoolAidHomeLogined({funcName:"loadGroupSelect"}).then((groups) => {
      var ret = [];
      groups.data.forEach((group) => {
        var row = {
          groupId : group.groupId,
          groupName: group.groupName,
        };
        ret.push(row);
      });
      this.groups = ret;
    });
  },
  methods: {
    // テキストアンケート追加
    addInputText () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id: createId,
        content: "",
        type: "0",
        typeName: "テキスト回答",
        charLimit: "",
        required: false,
        textFlg: true,
        checkFlg: false,
        radioFlg: false,
        option: [],
      };
      this.questionList.push(row);
    },

    // チェックボックスアンケート追加
    addInputCheck () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id: createId,
        content: "",
        type: "1",
        typeName: "チェックボックス回答",
        charLimit: null,
        required: false,
        textFlg: false,
        checkFlg: true,
        radioFlg: false,
        option: [],
      };
      this.questionList.push(row);
    },

    // ラジオボタンアンケート追加
    addInputRadio () {
      var createId;
      if (this.questionList.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id : createId,
        content: "",
        type: "2",
        typeName: "ラジオボタン回答",
        charLimit: null,
        required: false,
        textFlg: false,
        checkFlg: false,
        radioFlg: true,
        option: [],
      };
      this.questionList.push(row);
    },

    // アンケート削除
    removeQuestion (id) {
      this.questionList = this.questionList.filter(function(item) {
        return item.id != id;
      });
    },

    // チェックボックス選択肢追加
    addCheckOption (id) {
      var index = this.questionList.findIndex(item => item.id == id);
      var createId;
      if (this.questionList[index].option.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList[index].option.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id : createId,
        title : "",
      };
      this.questionList[index].option.push(row);
    },

    // ラジオボタン選択肢追加
    addRadioOption (id) {
      var index = this.questionList.findIndex(item => item.id == id);
      var createId;
      if (this.questionList[index].option.length == 0) {
        createId = 0;
      } else {
        var lastElem = this.questionList[index].option.slice(-1)[0];
        createId = lastElem.id + 1;
      }
      var row = {
        id : createId,
        title : "",
      };
      this.questionList[index].option.push(row);
    },

    // チェックボックス選択肢削除
    removeCheckOption (questionId, checkId) {
      var index = this.questionList.findIndex(item => item.id == questionId);
      this.questionList[index].option = this.questionList[index].option.filter(function(item) {
        return item.id != checkId;
      });
    },

    // ラジオボタン選択肢削除
    removeRadioOption (checkId, radioId) {
      var index = this.questionList.findIndex(item => item.id == checkId);
      this.questionList[index].option = this.questionList[index].option.filter(function(item) {
        return item.id != radioId;
      });
    },

    // アンケート情報登録
    async saveSurveyRegist() {
      for (var i = 0; i < this.questionList.length; i++) {
        if (this.questionList[i].type == "1" || this.questionList[i].type == "2") {
          if (this.questionList[i].option.length == 0){
            alert("選択肢を作成してください。");
            return;
          }
        }
      }
      if (this.questionList.length == 0) {
        alert("アンケート項目を作成してください");
        return;
      }
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      console.log(this.selectedUserKbn);
      var data = {
        funcName: "saveSurveyRegist",
        groupId : this.seletctGroupId,
        groupName : this.seletctGroupName,
        title : this.title,
        body : this.body,
        openDate : this.openDate,
        closeDate : this.closeDate,
        selectedUserKbn : this.selectedUserKbn,
        fileList : Array.from(this.fileList),
        mailSendFlg: this.mailSendFlg,
        questionList : this.questionList,
        targetIds : this.targetIds,
        anonymousFlg: this.anonymousFlg,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("登録しました。");
          this.$router.push({
            name: "GroupSelect",
          });
        } else {
          alert(res.data);
        }
      });
    },
    canselSurveyRegist(){
      this.$router.push({
        name: "GroupSelect",
      });
    },
    defaultDateSet() {
      var dt = new Date() ;
      const dtFrom = new Date(dt.setDate(dt.getDate() + 1));
      this.openDate = this.formatDate(dtFrom)  + "T07:00";
      
      dt = new Date() ;
      const dtTo =new Date( dt.setMonth(dt.getMonth()+1)) ;
      this.closeDate = this.formatDate(dtTo) + "T23:59";
      console.log("1月後",this.closeDate);
    } ,
    formatDate(dt){
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    },
    formatNum(item){
      var num = parseInt(item.charLimit);
      if (Number.isNaN(num) || num < 1) {
        num = "";
      }
      for (const question of this.questionList) {
        if (item.id == question.id) {
          question.charLimit = num;
          break;
        }
      }
    },
    // グループ選択モーダルを開く
    openGroupSelectDialog() {
      this.groupSelectDialog = true;
    },
    // グループ選択時
    changeGroupId() {
      var select = Enumerable.from(this.groups).where(i => i.groupId == this.seletctGroupId).toArray();
      this.seletctGroupName = select[0].groupName;
    },
  },
};
</script>