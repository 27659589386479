<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack" :disabled="lockBack">前のｱﾝｹｰﾄへ</v-btn>
        </div>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward" :disabled="lockForward">次のｱﾝｹｰﾄへ</v-btn>
        </div>
      </v-col>
    </v-row>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="374"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6">{{ title }}</v-card-title>
        <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0">投稿者：{{ createUser }}</v-card-text>
        <v-card-text class="d-flex justify-center flex-column">
          <v-textarea
            auto-grow
            aria-disabled="true"
            label="アンケート本文"
            style="white-space:pre;"
            v-model="body"
            hide-details="auto"
          >
          </v-textarea>
        </v-card-text>
        <!-- 匿名 -->
        <v-card-text v-if="anonymousFlg" class="d-flex justify-center flex-column">
          <div color="red">このアンケートは匿名での回答となります。学校が個人を特定することはありません。</div>
        </v-card-text>
        <v-card
          class="d-flex justify-center flex-column mx-auto my-0 flat"
          width="1200"
          color="#fff"
        >
          <v-list>
            <v-subheader>添付ファイル</v-subheader>
            <v-list-item-group  v-if="fileList.length" color="primary">
              <v-list-item v-for="item in fileList" :key="item.attachmentId">
                <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group  v-if="!fileList.length" color="primary">
              <v-list-item>ファイルはありません</v-list-item>
            </v-list-item-group>
          </v-list>

        </v-card>
        <v-card v-for="(question, index) in questionList" :key="question.id" class="mx-4 py-2 mb-4">
          <!-- テキスト回答のアンケート -->
          <div v-if="question.type == '0'">
            <div class="mx-3 my-2">
              <v-row class="mb-0 mt-2">
                <v-col cols="4" class="py-0">
                  <div>{{ (index + 1) }}問目</div>
                </v-col>
                <v-col cols="8" class="py-0">
                  <div><span v-if="question.required" style="color:red">*</span>{{ question.content }}</div>
                </v-col>
              </v-row>
            </div>
            <div class="mx-3 mt-0">
              <ValidationProvider name="回答" :rules="question.rules" v-slot="{ errors }" :vid="question.id">
                <v-row>
                  <v-col cols="auto" class="pb-0">
                    <v-textarea
                    v-model="question.value"
                    label="回答"
                    outlined
                    dense
                    hide-details="auto"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
            </div>
          </div>

          <!-- チェックボックス回答のアンケート -->
          <div class="mx-3 mt-2" v-if="question.type=='1'">
            <v-row class="mb-0 my-2">
              <v-col cols="4" class="py-0">
                <div>{{ (index + 1) }}問目</div>
              </v-col>
              <v-col cols="8" class="py-0">
                <div><span v-if="question.required" style="color:red">*</span>{{ question.content }}</div>
              </v-col>
            </v-row>
            <ValidationProvider name="チェック" :rules="question.rules" v-slot="{ errors }" :vid="question.id">
              <v-row v-for="checkOption in question.option" :key="checkOption.id" class="my-0">
                <v-col cols="auto" class="py-0">
                  <v-checkbox v-model="question.value"
                  :label="checkOption.title"
                  :value="checkOption.id"
                  dense
                  hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <!-- ラジオボタン回答のアンケート -->
          <div class="mx-3 mt-2" v-if="question.type=='2'">
            <v-row class="mb-0 my-2">
              <v-col cols="4" class="py-0">
                <div>{{ (index + 1) }}問目</div>
              </v-col>
              <v-col cols="8" class="py-0">
                <div><span v-if="question.required" style="color:red">*</span>{{ question.content }}</div>
              </v-col>
            </v-row>
            <ValidationProvider name="ラジオ" :rules="question.rules" v-slot="{ errors }" :vid="question.id" :skipIfEmpty="false">
              <v-row class="my-0">
                <v-col cols="auto" class="py-0">
                  <v-radio-group v-model="question.value">
                    <v-radio v-for="radioOption in question.option" :key="radioOption.id"
                      :label="radioOption.title"
                      :value="radioOption.id"
                      dense
                      hide-details
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <p class="error">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
        </v-card>

        <v-card-actions>
          <v-btn color="grey" class="mr-auto mt-0 black--text" @click="cancelSurveyDetail" width="45%">戻る</v-btn>
          <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveSurveyDetails" :disabled="invalid" width="45%">回答</v-btn>
        </v-card-actions>  

      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { saveAs } from 'file-saver';
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['responseId','surveyListBuff','surveyListIndex'],
  data() {
    return {
      userTop : "",
      title : "",
      body : "",
      surveyId : "",
      groupId : "",
      createUser : "",
      fileList: [],
      questionList : [],
      lockBack: false,
      lockForward: false,
      anonymousFlg: false,
    };
  },
  watch: {
    responseId: async function () {
      await this.load();
    }
  },
  // 初期表示時データ読み込み
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.lockBack = (this.surveyListIndex == 0)?true:false;
      this.lockForward = (this.surveyListBuff.length == (this.surveyListIndex+1))?true:false;
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "loadSurveyDetails",
        responseId: this.responseId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.userTop = doc.data.userTop;
        this.title = doc.data.title;
        this.body = doc.data.body;
        this.surveyId = doc.data.surveyId;
        this.groupId = doc.data.groupId;
        this.createUser = doc.data.createUser;
        this.fileList = doc.data.fileList;
        this.questionList = doc.data.questionList;
        this.anonymousFlg = doc.data.anonymousFlg;
        // ローディング非表示
        this.$root.loading = false;
      }).catch((e) => {
        console.log(e.message);
        this.$root.loading = false;
      });
    },
    // 前の連絡へボタン
    toBack() {
      this.$router.push({
        name: "SurveyDetails",
        params: {
          responseId : this.surveyListBuff[this.surveyListIndex - 1],
          surveyListBuff: this.surveyListBuff,
          surveyListIndex: this.surveyListIndex - 1,
        } 
      });
    },
    // 次の連絡へボタン
    toForward() {
      this.$router.push({
        name: "SurveyDetails",
        params: {
          responseId : this.surveyListBuff[this.surveyListIndex + 1],
          surveyListBuff: this.surveyListBuff,
          surveyListIndex: this.surveyListIndex + 1,
        } 
      });
    },
    // 戻るボタン押下
    cancelSurveyDetail(){
      this.$router.push({
        name: this.userTop,
      });
    },
    // 回答ボタン押下
    async saveSurveyDetails(){
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveSurveyDetails",
        surveyId : this.surveyId,
        groupId : this.groupId,
        questionList : this.questionList,
      }

      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      this.$router.push({
        name: this.userTop,
      });
    },
    async onClickDwonload(item) {
      saveAs(item.url, item.fileName);
    },
  }
};
</script>