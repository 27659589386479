<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row dense>
            <v-col cols="12" justify="center">
              アンケート集計({{ title }})
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              対象者数：{{ totalCount }}
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              回答数：{{ doneCount }}
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" xl="4">
              未回答数：{{ notYetCount }}
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- アンケート明細(ラジオタイプ・チェックタイプのみ) -->
    <v-row dense>
      <v-col v-for="(question, index) in questionList" :key="index" cols="12" sm="6" md="6">
        <v-card  class="d-flex justify-center flex-column mx-auto my-6 flat" color="#fff">
          <v-card-title>
            設問{{ question.index + 1 }}
          </v-card-title>
          <!-- 調査内容 -->
          <v-card-subtitle>
            {{ question.content }}
          </v-card-subtitle>
          <v-card-text>
            <!-- 集計結果グラフ -->
            <Doughnut :param_data="question.data" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Doughnut from './components/Doughnut.vue'

export default {
  props: ['id'],
  components: {
    Doughnut
  },
  // 初期表示時データ読み込み
  async created() {
    await this.reload();
  },
  data() {
    return {
      title: "",
      totalCount: "",
      doneCount: "",
      notYetCount: "",
      questionList: [],
    }
  },
  methods: {
    async reload() {
      var param = {
        funcName: "loadSurveyChartView",
        surveyId: this.id,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        this.title = ret.data.title;
        this.totalCount = ret.data.totalCount;
        this.doneCount = ret.data.doneCount;
        this.notYetCount = ret.data.notYetCount;
        this.questionList = ret.data.questionList;
      });
    }
  }
}
</script>