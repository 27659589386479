<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          出欠事前連絡
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center">
          出欠区分を選んでから、日付を選択してください
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row justify="center">
            <div>
                <v-col cols="12">
                  <v-radio-group
                      label="出欠区分"
                      v-model="syukketuKbn"
                      class="justify-center"
                      row
                      >
                      <v-radio color="red" value="1">
                          <template v-slot:label>
                              <div class="text-no-wrap red lighten-3" style="width: 2rem;">
                                  欠席
                              </div>
                          </template>
                      </v-radio>
                      <v-radio color="green" value="2">
                          <template v-slot:label>
                              <div class="text-no-wrap green lighten-3" style="width: 2rem;">
                                  遅刻
                              </div>
                          </template>
                      </v-radio>
                      <v-radio color="blue" value="3">
                          <template v-slot:label>
                              <div class="text-no-wrap blue lighten-3" style="width: 4rem;">
                                  早退予定
                              </div>
                          </template>
                      </v-radio>
                      <v-radio color="purple" value="4">
                          <template v-slot:label>
                              <div class="text-no-wrap purple lighten-3" style="width: 7rem;">
                                  遅刻⇒早退予定
                              </div>
                          </template>
                      </v-radio>
                  </v-radio-group>
                  </v-col>
              </div>
          </v-row>
          <v-sheet
              tile
              height="54"
              class="d-flex"
          >
              <v-row dense>
                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                      <!-- 前月へボタン -->
                      <v-btn
                          :disabled="lockFlg"
                          color="orange"
                          @click="changeMonth(true)"
                      >
                          前月へ
                      </v-btn>
                  </v-col>
                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                      {{ year }} 年 {{ month }} 月
                  </v-col>
                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                      <!-- 次月へボタン -->
                      <v-btn
                          :disabled="!lockFlg"
                          color="orange"
                          @click="changeMonth(false)"
                      >
                          次月へ
                      </v-btn>
                  </v-col>
              </v-row>
          </v-sheet>
          <v-sheet>
            <div class="d-flex justify-space-around">
                <div v-for="w in dayOfWeek"
                    :key="w.value"
                    :class="w.color"
                    >
                    {{ w.label }}
                </div>
            </div>
            <v-calendar
                ref="calendar"
                v-model="focus"
                locale="ja-jp"
                hide-header
            >
                <template v-slot:day-label="{ date,past,present,weekday }">
                    <v-row dense>
                        <v-col v-bind:class="(past||present)?'':dayOfWeekStyle[weekday]">
                            <!-- 指定可能日 -->
                            <v-btn
                            v-if="!(past||present)"
                            icon
                            :color="cellColor(date)"
                            :outlined="isInput(date)"
                            @click="clickDate(date)"
                            >
                            {{dayFormat(date)}}
                            </v-btn>
                            <!-- 指定無効日 -->
                            <v-btn
                            v-if="(past||present)"
                            icon>
                            {{dayFormat(date)}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-calendar>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <v-btn color="grey" class="black--text" @click="toAttendanceRegist" width="100%">
            戻る
        </v-btn>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <v-btn color="blue" class="black--text" @click="inputDataDialog = true" :disabled="this.viewList.length == 0" width="100%">
            内容確認(備考登録)
        </v-btn>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <v-btn color="accent" class="black--text" @click="saveAttendanceFutureRegist" width="100%">
            連絡する
        </v-btn>
      </v-col>
    </v-row>
    <!-- 登録内容モーダル -->
    <v-dialog
      v-model="inputDataDialog"
      max-width="370"
      scrollable
      persistent
    >
        <ValidationObserver ref="obs" v-slot="{ invalid }">
            <v-card>
            
            <v-card-title class="pt-2 pb-0" style="font-size:1.23rem;">
                連絡内容
            </v-card-title>
            <v-card-title class="pt-0 pb-0">
                <v-row dense>
                    <v-col cols="12" justify="center" class="d-flex">
                        <ValidationProvider name="備考一括設定内容" rules="max:150" v-slot="{ errors }">
                        <v-text-field
                            v-model="bikoTemplate"
                            placeholder="備考一括設定内容"
                            outlined
                            dense
                            clearable
                            style="width:100%"
                            hide-details="auto"
                            ></v-text-field>
                            <p class="error">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <v-btn class="ml-2 black--text" color="accent" @click="copyBiko" :disabled="invalid">
                            備考一括設定
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <div v-for="item in viewList" :key="item.date">
                    <v-row dense>
                        <v-col cols="12" justify="center" class="d-flex">
                            <ValidationProvider name="備考" rules="max:150" v-slot="{ errors }" :vid="item.date">
                                <span :class="dayOfWeek[item.weekday].color">{{ item.dateDisp }}</span>
                                <span :class="item.style" style="width: 5rem;">{{ item.syukketuKbnName }}</span>
                                <span class="ml-2" v-if="!item.editFlg">{{ item.biko }}</span>
                                <span v-if="!item.editFlg && !item.biko">未設定</span>
                                <v-btn icon @click="item.editFlg = true" v-if="!item.editFlg"><v-icon>mdi-pencil</v-icon></v-btn>
                                <v-text-field
                                    v-model="item.biko"
                                    placeholder="備考"
                                    outlined
                                    dense
                                    clearable
                                    style="width:310px"
                                    v-if="item.editFlg"
                                    hide-details="auto"
                                    ></v-text-field>
                                    <p class="error">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
            <v-row dense>
                <v-col cols="12" class="ml-1" justify="center">
                <v-btn block color="grey" @click="closeDialog" :disabled="invalid">
                    閉じる
                </v-btn>
                </v-col>
            </v-row>
            </v-card-actions>
            
            </v-card>
        </ValidationObserver>
    </v-dialog>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format,parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import Enumerable from "linq";
export default {
    props: ['id'],
    data: () => ({
        syukketuKbn: "1",
        year: format(new Date().setDate(new Date().getDate() + 1), 'yyyy', { locale: ja }),
        month: format(new Date().setDate(new Date().getDate() + 1), 'M', { locale: ja }),
        focus: format(new Date().setDate(new Date().getDate() + 1), 'yyyy-MM-dd', { locale: ja }),
        lockFlg: true,
        dayOfWeekStyle: ['sun','','','','','','sat'],
        dayOfWeek: [
            { value: 0, label: "日", color: "red--text" },
            { value: 1, label: "月", color: "" },
            { value: 2, label: "火", color: "" },
            { value: 3, label: "水", color: "" },
            { value: 4, label: "木", color: "" },
            { value: 5, label: "金", color: "" },
            { value: 6, label: "土", color: "blue--text" },
        ],
        all: [],
        kesseki: [],
        chikoku: [],
        sotai: [],
        chiso: [],
        viewList: [],
        inputDataDialog: false,
        bikoTemplate: "",
    }),
    async created() {
        // ローディング開始
        this.$root.loading = true;
        const functions = getFunctions(this.$firebase);
        functions.region = "asia-northeast1";
        const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

        const data = {
            funcName: "loadAttendanceFutureRegist",
            studentId: this.id
        };

        await schoolAidHomeLogined(data).then((res) => {
            console.log(res);
            // TODO データ取得処理
            this.all = res.data.all;
            this.kesseki = res.data.kesseki;
            this.chikoku = res.data.chikoku;
            this.sotai = res.data.sotai;
            this.chiso = res.data.chiso;
            this.viewList = res.data.viewList;
        }).catch((e) =>{
            console.log(e);
            this.$root.loading = false;
        });

        // ローディング終了
        this.$root.loading = false;
    },
    methods: {
        // カレンダーの月変更
        changeMonth: function(flg){
            if(flg){
                this.$refs.calendar.prev();
            } else {
                this.$refs.calendar.next();
            }
            var buff = parse(this.focus, 'yyyy-MM-dd', new Date());
            this.year = format(buff, 'yyyy', { locale: ja });
            this.month = format(buff, 'M', { locale: ja });
            this.lockFlg = !this.lockFlg;
        },
        // 日付ラベル設定
        dayFormat: function(date){
            var buff = parse(date, 'yyyy-MM-dd', new Date());
            // 月初判定
            if(buff.getDate() == 1){
                return format(buff, 'M/d', { locale: ja })
            } else {
                return format(buff, 'd', { locale: ja })
            }
        },
        // 日付選択
        clickDate(date) {
            console.log("clickDate",date);
            const index0 = this.all.indexOf(date);
            if (index0 === -1) {
                // 行追加
                this.all.push(date);
                var buff = parse(date, 'yyyy-MM-dd', new Date());
                var row = {
                    date: date,
                    dateDisp: format(buff, 'M月d日(E)', { locale: ja }),
                    weekday: buff.getDay(),
                    syukketuKbn: this.syukketuKbn,
                    orderNo: buff.getTime(),
                    editFlg: false,
                };
                // 指定区分への追加
                switch (this.syukketuKbn) {
                    case "1":
                        this.kesseki.push(date);
                        row.syukketuKbnName = "欠席";
                        row.biko = "";
                        row.style = "red lighten-3";
                        break;
                    case "2":
                        this.chikoku.push(date);
                        row.syukketuKbnName = "遅刻";
                        row.biko = "";
                        row.style = "green lighten-3";
                        break;
                    case "3":
                        this.sotai.push(date);
                        row.syukketuKbnName = "早退予定";
                        row.biko = "";
                        row.style = "blue lighten-3";
                        break;
                    case "4":
                        this.chiso.push(date);
                        row.syukketuKbnName = "遅刻⇒早退予定";
                        row.biko = "";
                        row.style = "purple lighten-3";
                        break;
                    default:
                        break;
                }
                this.viewList.push(row);
                this.viewList = Enumerable.from(this.viewList).orderBy(x => { return x.orderNo }).toArray();
            } else {
                // 行削除
                this.all.splice(index0, 1);
                // 既存の区分配列からの削除
                const index2 = this.kesseki.indexOf(date);
                if(index2 !== -1)this.kesseki.splice(index2, 1);
                const index3 = this.chikoku.indexOf(date);
                if(index3 !== -1)this.chikoku.splice(index3, 1);
                const index4 = this.sotai.indexOf(date);
                if(index4 !== -1)this.sotai.splice(index4, 1);
                const index5 = this.chiso.indexOf(date);
                if(index5 !== -1)this.chiso.splice(index5, 1);
                this.viewList = Enumerable.from(this.viewList).where(x => { return x.date !== date }).toArray();
            }
        },
        // 選択日出欠区分判定
        cellColor(date) {
            if(this.kesseki.some((x) => x === date)){
                return 'red';
            } else if(this.chikoku.some((x) => x === date)){
                return 'green';
            } else if(this.sotai.some((x) => x === date)){
                return 'blue';
            } else if(this.chiso.some((x) => x === date)){
                return 'purple';
            } else {
                return 'black'
            }
        },
        // 入力判定
        isInput(date) {
            return this.all.some((x) => x === date);
        },
        // 備考一括更新
        copyBiko() {
            for (const row of this.viewList) {
                row.biko = this.bikoTemplate;
            }
        },
        // ダイアログを閉じる
        closeDialog() {
            this.inputDataDialog = false;
            this.bikoTemplate = "";
            for (const row of this.viewList) {
                row.editFlg = false;
            }
        },
        // 保存処理
        async saveAttendanceFutureRegist(){
            // ローディング表示
            this.$root.loading = true;
            const functions = getFunctions(this.$firebase);
            functions.region = "asia-northeast1";
            const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");

            // 保存用配列の確保
            var syukketuList = [];
            for (const cell of this.viewList) {
                syukketuList.push({
                    yyyymmdd: format(parse(cell.date, 'yyyy-MM-dd', new Date()), 'yyyyMMdd', { locale: ja }),
                    syukketuKbn: cell.syukketuKbn,
                    biko: cell.biko,
                });
            }

            var data = {
                funcName: "saveAttendanceFutureRegist",
                studentId : this.id,
                syukketuList : syukketuList,
            };

            await schoolAidHomeLogined(data).then((res) => {
                // ローディング非表示
                this.$root.loading = false;
                console.log(res.data);
                alert("連絡しました。");
            }).catch((e) =>{
                this.error = e.message;
                this.$root.loading = false;
            });
        },
        toAttendanceRegist() {
            this.$router.push({
                name: "AttendanceRegist",
                params: {
                id: this.id,
                }
            });
        },
    },
};
</script>

<style>
.v-calendar-weekly__day.v-past {
    background: rgba(97, 97, 97) !important;
}
.v-calendar-weekly__day.v-present {
    background: rgba(97, 97, 97) !important;
}
.sun {
    background: rgba(255, 204, 188) !important;
}
.sat {
    background: rgba(207, 216, 220) !important;
}
</style>