<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center black--text" outlined>
          アンケート一覧
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <!-- 検索用のテキスト入力 -->
        <v-autocomplete
          v-model="groupFilterValue"
          label="グループ指定"
          placeholder="グループ指定"
          :items="groupNames"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <!-- 検索用のテキスト入力 -->
        <v-autocomplete
          v-model="postUsersFilterValue"
          label="作成者指定"
          placeholder="作成者指定"
          :items="postUsers"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
        <v-switch 
          v-model="viewFavoriteOnly"
          dense
          class="mt-1"
          label="お気に入りのみ"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="surveys"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isSurveysLoding" loading-text="読込中"
          >
          <!-- 投稿日と投稿者 -->
          <template #[`item.postUser`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.postDate }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
          </template>
          <!-- グループ名と代表者 -->
          <template #[`item.groupName`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.groupName }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.ownerUser }}</v-col></v-row>
          </template>
          <template #[`item.target`]="{ item }">
            <v-icon x-large v-if="item.studentFlg">$student</v-icon>
            <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
            <v-icon x-large v-if="item.teacherFlg">$teacher</v-icon>
            <div v-if="item.targetSelectFlg">【限定】</div>
          </template>
          <!-- お気に入り -->
          <template #[`item.favoriteFlg`]="{ item }">
            <v-btn icon @click="changeFavoriteSurveys(item)" v-if="item.favoriteFlg"><v-icon color="orange">mdi-star</v-icon></v-btn>
            <v-btn icon @click="changeFavoriteSurveys(item)" v-if="!item.favoriteFlg"><v-icon>mdi-star-outline</v-icon></v-btn>
          </template>
          <!-- 編集と通知 -->
          <template #[`item.editAndMail`]="{ item }">
            <v-row dense><v-col cols="12" justify="center"><v-icon class="ml-2" @click="onClickEditItem(item)">mdi-pencil</v-icon></v-col></v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-icon class="ml-2" @click="openMailModal(item)">mdi-email-outline</v-icon>
              </v-col>
            </v-row>
          </template>
          <!-- 公開期間 -->
          <template #[`item.publishDate`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.openDate }}～</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.closeDate }}</v-col></v-row>
          </template>
          <!-- 公開状態 -->
          <template #[`item.isOpen`]="{ item }">
            <v-btn color="accent" class="black--text" v-if="item.publishFlg" @click="onClickChangeOpenStatus(item)">公開中</v-btn>
            <v-btn color="grey" class="black--text" v-if="!item.publishFlg" @click="onClickChangeOpenStatus(item)">停止中</v-btn>
          </template>
          <!-- ユーザー閲覧数 -->
          <template #[`item.count`]="{ item }">
            <v-btn :loading="item.isCountLoading" @click="onClickAnswerStatusSurvey(item)">{{ item.count }}</v-btn>
          </template>
          <!-- Excel出力 -->
          <template #[`item.action`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-btn icon @click="onClickChartView(item)" v-if="item.canChartViewFlg"><v-icon color="blue">mdi-chart-arc</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-btn icon @click="onClickExport(item)" v-if="item.canExportFlg"><v-icon color="green">mdi-file-excel</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-btn icon @click="onClickDeleteItem(item)"><v-icon color="red">mdi-delete</v-icon></v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-btn fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
      <v-icon color="white">mdi-refresh</v-icon>
    </v-btn>
    <!-- メール送信モーダル -->
    <v-dialog
      v-model="mailDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="ml-5">アンケートを通知する</v-card-title>
        <v-list-item-subtitle class="ml-3" style="font-size:.835rem;">非公開の場合は公開になり次第通知されます</v-list-item-subtitle>
        <v-card-text>
          <v-radio-group v-model="mailType" :mansdatory="false">
            <v-radio label="アンケートの登録を通知する" value="A-0"></v-radio>
            <v-radio label="アンケートの修正を通知する" value="A-1"></v-radio>
            <v-radio label="未回答者に対して再通知する" value="A-2"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="grey" @click="mailDialog = false">
                閉じる
              </v-btn>
            </v-col>
            <v-col cols="3" justify="center"></v-col>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="accent" @click="sendMailSurvey()">
                送信予約
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 入力状況出力モーダル -->
    <v-dialog
      v-model="exportDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="pl-5" style="font-size:1.012rem;">アンケートの入力状況を出力する</v-card-title>
        <v-card-text>
          アンケートの入力状況をExcelにて出力可能です。
          システムによるファイルの準備ができましたら、
          <p class="font-weight-bold red--text">メールアドレス宛にダウンロード用のリンクを送付します</p>
          <v-radio-group v-model="exportType" :mansdatory="false">
            <v-radio label="アンケートの入力状況を出力する" value="A-0"></v-radio>
            <v-radio label="アンケートの結果を出力する" value="A-1"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="grey" @click="exportDialog = false">
                閉じる
              </v-btn>
            </v-col>
            <v-col cols="3" justify="center"></v-col>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="accent" :disabled="exportDisabled" @click="exportExcelSurveyStatus()">
                出力予約
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  data() {
    return {
      postUsersFilterValue: "",
      groupFilterValue: "",
      viewFavoriteOnly: false,
      postUsers : [],
      groupNames : [],
      headers: [
        { text: "作成日時/作成者", value: "postUser", width: "15%", filter: this.postUserFilter },
        { text: "タイトル", value: "title", width: "35%" },
        { text: "グループ名/代表者", value: "groupName", width: "35%", filter: this.groupNameFilter },
        { text: "対象", value: "target" },
        { text: "お気に入り", value: "favoriteFlg", width: "5%", filter: this.favoriteFilter },
        { text: "匿名", value: "anonymousState", width: "5%" },
        { text: "編集/通知", value: "editAndMail", width: "5%" },
        { text: "公開期間", value: "publishDate", width: "20%" },
        { text: "公開状態", value: "isOpen", width: "5%" },
        { text: "全体：回答済：未回答", value: "count", width: "5%" },
        { text: "操作", value: "action", width: "5%" },
      ],
      surveys: [],
      isSurveysLoding: false,
      mailDialog: false,
      exportDialog: false,
      mailSurveyId: "",
      mailType: "1",
      exportSurveyId: "",
      exportType: "A-0",
      exportDisabled: false,
      perPage: 30,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.perPage = this.$store.state.parPageSurveyList;
    await this.reload();
  },
  watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageSurveyList', newVal);
    },
  },
  methods: {
    // 投稿者名フィルタ
    postUserFilter(value) {
      if (!this.postUsersFilterValue) {
        return true;
      }
      return value.toString().includes(this.postUsersFilterValue);
    },
    // グループ名フィルタ
    groupNameFilter(value) {
      if (!this.groupFilterValue) {
        return true;
      }
      return value.toString().includes(this.groupFilterValue);
    },
    // お気に入りフィルタ
    favoriteFilter(value) {
      if (!this.viewFavoriteOnly) {
        return true;
      }
      return value;
    },
    async changeFavoriteSurveys(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteSurveys",
        surveyId: item.surveyId,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      item.favoriteFlg = !item.favoriteFlg;
    },
    async reload() {
      var temp_postUsers = new Set();
      var temp_groupNames = new Set();
      this.surveys = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isSurveysLoding = true;
      await schoolAidHomeLogined({funcName:"loadSurveyList"}).then((surveys) => {
        var ret = [];
        surveys.data.forEach((survey) => {
          temp_postUsers.add(survey.postUser);
          temp_groupNames.add(survey.groupName);
          var count = survey.allCount + "：" + survey.doneCount + "：" + survey.notYetCount;
          var row = {
            postDate: survey.postDate,
            postUser: survey.postUser,
            groupName : survey.groupName,
            ownerUser : survey.ownerUser,
            studentFlg: survey.studentFlg,
            parentFlg: survey.parentFlg,
            teacherFlg: survey.teacherFlg,
            title : survey.title,
            openDate: survey.openDate,
            closeDate: survey.closeDate,
            publishFlg : survey.publishFlg,
            count : count,
            allCount : survey.allCount,
            surveyId : survey.surveyId,
            canExportFlg : survey.canExportFlg,
            isCountLoading: survey.isCountLoading,
            mailAddressAuthFlg: survey.mailAddressAuthFlg,
            favoriteFlg: survey.favoriteFlg,
            targetSelectFlg: survey.targetSelectFlg,
            canChartViewFlg: survey.canChartViewFlg,
            anonymousState: (survey.anonymousFlg) ? "匿名" : "",
          };

          ret.push(row);
        });
        this.surveys = ret;
        this.isSurveysLoding = false;
        this.postUsers = Array.from(temp_postUsers);
        this.groupNames = Array.from(temp_groupNames);
      });
    },

    // 公開状態変更
    async onClickChangeOpenStatus(item) {
      var result = confirm("公開状態を変更してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "changeOpenStatusSurvey",
        surveyId: item.surveyId,
        publishFlg: item.publishFlg,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.reload();
    },

    // アンケート修正
    onClickEditItem(item) {
      this.$router.push({
        name: "SurveyEdit",
        params: {
          id: item.surveyId,
        }
      });
    },
    // 論理削除
    async onClickDeleteItem(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "deleteSurvey",
        surveyId: item.surveyId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.reload();
    },
    // 通知送信モーダルを開く
    openMailModal(item) {
      this.mailSurveyId = item.surveyId;
      this.mailType = "A-1";
      this.mailDialog = true;
    },
    // 通知処理
    async sendMailSurvey() {
      var result = confirm("通知してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "sendMailSurvey",
        surveyId: this.mailSurveyId,
        mailType: this.mailType,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then(() => {
        alert("受け付けました");
      });
      // ローディング終了
      this.$root.loading = false;
      this.mailDialog = false;
    },
    // 回答状況一覧
    onClickAnswerStatusSurvey(item) {
      if(item.allCount > 200){
        var result = confirm("対象者が200名を超える場合はExcel出力を推奨しています。続行しますか？");
        if (!result) {
          return;
        }
      }

      this.$router.push({
        name: "AnswerStatusSurvey",
        params: {
          id: item.surveyId,
        }
      });
    },
    // 入力状況確認
    onClickExport(item) {
      this.exportSurveyId = item.surveyId;
      this.exportType = "A-0";
      this.exportDialog = true;
      this.exportDisabled = !item.mailAddressAuthFlg;
    },
    // エクセル出力処理
    async exportExcelSurveyStatus() {
      var result = confirm("出力してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "exportExcelSurveyStatus",
        surveyId: this.exportSurveyId,
        exportType: this.exportType,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then(() => {
        alert("受け付けました");
      });
      // ローディング終了
      this.$root.loading = false;
      this.exportDialog = false;
    },
    // アンケートグラフ表示
    onClickChartView(item) {
      this.$router.push({
        name: "SurveyChartView",
        params: {
          id: item.surveyId,
        }
      });
    },
  },
};
</script>

<style scoped>
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}

</style>