<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack">前の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward">次の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="10" md="10" lg="10" xl="10">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row dense>
            <v-col cols="8">
              <div align="center">
                {{ yyyymmddDisp }}の早退確認
              </div>
            </v-col>
            <v-col cols="4">
              <div align="right">
                <v-btn color="grey" small class="black--text" @click="calendar">日付選択</v-btn>

              </div>
            </v-col>
          </v-row>
          <Datepicker
            ref="picker"
            :monday-first="true"
            wrapper-class="calender-text-box-wrapper"
            input-class="calender-text-box"
            placeholder=""
            :language="language"
            @selected=dateSelected />
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <!-- グループフィルタ -->
        <v-select
          v-model="groupFilterValue"
          label="グループ指定"
          :items="groupList"
          item-text="groupName"
          item-value="filterItem"
          outlined
          dense
          clearable
          multiple
          chips
          deletable-chips
          hide-details="auto"
          background-color="white"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-resize="updHeight">
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="members"
          :items-per-page="-1"
          :footer-props="{'disable-items-per-page': true}"
          dense
          disable-sort
          :height="tableHeight"
          :fixed-header="true"
          :item-class="rowStyle"
          :loading="isListLoding" loading-text="読込中"
        >
          <template #[`header.name`]="{}"> 
            <v-row dense><v-col cols="12" justify="center">
              生徒氏名
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              保護者氏名
            </v-col></v-row>
          </template>
          <template #[`header.filterItem`]="{}"> 
            <v-row dense><v-col cols="12" justify="center">
              課程/部/科
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              学年/学級/出席番号
            </v-col></v-row>
          </template>
          <template #[`item.name`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              <v-icon class="ml-2" v-if="item.mailAddressAuthFlg">mdi-email-outline</v-icon>
              <v-icon class="ml-2" v-else>mdi-phone</v-icon>{{ item.name }}
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              <v-icon class="ml-2" v-if="item.mailAddressAuthFlgParent">mdi-email-outline</v-icon>
              <v-icon class="ml-2" v-else>mdi-phone</v-icon>{{ item.parentName }}
            </v-col></v-row>
          </template>
          <template #[`item.filterItem`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              {{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
            </v-col></v-row>
          </template>
          <template #[`item.sotaiInfo1`]="{ item }">
            <v-row dense>
              <v-col cols="6" justify="center">
                早退事由：{{ item.leavingReason }}
              </v-col>
              <v-col cols="6" justify="center">
                保護者確認：{{ item.unreadFlg }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" justify="center">
                下校開始予定時刻：{{ item.leavingTime }}
              </v-col>
              <v-col cols="6" justify="center">
                最終更新者：
                <span v-if="item.updateUserKbn == '1'" class="red--text">{{ item.updateUserName }}</span>
                <span v-else>{{ item.updateUserName }}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                保護者への連絡：{{ item.comStatusName }}
              </v-col>
            </v-row>
          </template>

          <template #[`item.action1`]="{ item }">
            <v-btn color="accent" class="black--text" @click="onClickEditItem(item)" :disabled="item.pastLockFlg">返信</v-btn>
          </template>


          <template #[`item.action2`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              <v-btn color="accent" class="black--text text-pre-wrap" style="height: auto;" @click="onClickGatePassageItem(item)"
             :disabled="nowDateFlg ? nowDateFlg : item.gatePassageFlg">{{ btnGateText }}</v-btn>
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              {{ item.gatePassageTime }}
            </v-col></v-row>
          </template>
          
          <template #[`item.action3`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              <v-btn color="accent" class="black--text" @click="onClickApprovalItem(item)" :disabled="item.pastLockFlg">確定</v-btn>
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              <v-icon class="ml-2" v-if="item.approvalFlg">mdi-check-outline</v-icon>
            </v-col></v-row>
          </template>

          <template #[`item.arrivalStatusFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.arrivalStatusFlg">mdi-check-outline</v-icon>
            <span v-if="item.arrivalStatusFlg">{{ item.arrivalTime }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-btn fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
      <v-icon color="white">mdi-refresh</v-icon>
    </v-btn>
    <!-- 早退確定モーダル -->
    <v-dialog
      v-model="leavingApprovalDialog"
      max-width="290"
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="374"
          color="#fff"
        >
          <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p>早退確定({{ approvalStudentNm }})</p>
          </v-card-title>

          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定出欠"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定出欠"
                      item-text="name"
                      item-value="code"
                      v-model="selectedMain"
                      :items="selectMain"
                      outlined
                      @change="changeMain"
                      hide-details="auto"
                    ></v-select>
                  <p class="error">{{ errors[0] }}</p>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定理由"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定理由"
                      item-text="name"
                      item-value="code"
                      v-model="approvalKbn"
                      :items="selectSubFilter"
                      outlined
                      @change="changeSub"
                      hide-details="auto"
                    ></v-select>
                  <p class="error">{{ errors[0] }}</p>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider v-if="approvalKbn.endsWith('99')" name="その他理由" rules="required|max:500" v-slot="{ errors }">
                  <v-text-field
                    v-model="approvalText"
                    label="その他理由"
                    placeholder="その他理由"
                    outlined
                    dense
                    v-if="approvalKbn.endsWith('99')"
                    hide-details="auto"
                  ></v-text-field>
                  <p v-if="approvalKbn.endsWith('99')" class="error">{{ errors[0] }}</p>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" class="black--text" @click="leavingApprovalDialog = false" width="49%">
              閉じる
            </v-btn>
            <v-btn color="accent" class="black--text" @click="sotaiApprovalSave()" :disabled="invalid" width="49%">
              確定
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import Enumerable from "linq";
export default {
  props: ['param_yyyymmdd'],
  watch: {
    yyyymmdd: async function(newVal, oldVal) {
      console.log(newVal, oldVal)
      await this.reload();
    },
    groupFilterValue: function(newVal) {this.$store.dispatch('setSearchConditionSyukketuGroup', newVal)},
  },
  data() {
    return {
      yyyymmdd: "",
      yyyymmddDisp: "",
      headers: [
        { text: "生徒氏名 保護者氏名", value: "name", width: "13%" },
        { text: "課程/部/科 学年/学級/出席番号", value: "filterItem", width: "13%", filter: this.groupFilter },
        { text: "早退情報", value: "sotaiInfo1" },
        { text: "", value: "action1", width: "5%" },
        { text: "通用門通過", value: "action2", width: "8%" },
        { text: "早退確定", value: "action3", width: "8%" },
        { text: "生徒帰宅連絡", value: "arrivalStatusFlg", width: "8%" },
      ],
      members: [],
      isListLoding: false,
      language:{
        language: 'Japanese',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        days: ['日', '月', '火', '水', '木', '金', '土'],
        yearSuffix: '年',
        ymd: true,
      },
      groupFilterValue: [],
      groupList: [],
      btnGateText: "事務室\n確認",
      nowDateFlg: false,

      // 早退確定用
      approvalStudentId: "",
      approvalStudentNm: "",
      leavingApprovalDialog: false,
      selectMain: [],
      selectedMain: "", //初期値
      selectSub: [],
      selectSubFilter: [],
      approvalKbn: "", //初期値
      approvalText: "", //初期値

      tableHeight: 0,  // v-data-table 高さ
    };
  },
  // 初期表示時データ読み込み
  async created() {
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    schoolAidHomeLogined({funcName: "getSyukketuGroup"}).then((ret) => {
      this.groupList = ret.data;
      this.groupFilterValue = this.$store.state.searchConditionSyukketuGroup;
    });
    
    await this.reload();
  },
  methods: {
    // グループフィルタ
    groupFilter(value) {
      if (this.groupFilterValue.length == 0) {
        return true;
      }
      return this.groupFilterValue.includes(value.toString());
    },
    // データピッカー開閉
    calendar() {
        if(!this.$refs.picker.isOpen) {
            this.$refs.picker.$el.querySelector("input").focus();
            this.$refs.picker.showCalendar();
        } else {
            this.$refs.picker.close();
        }
    },
    // データピッカー日付選択
    dateSelected(selectedDate) {
        this.yyyymmdd = format(selectedDate, 'yyyy-MM-dd', { locale: ja });
        this.yyyymmddDisp = format(selectedDate, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 前の日へボタン
    toBack() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() - 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 次の日へボタン
    toForward() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() + 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 早退登録
    onClickEditItem(item) {
      this.$router.push({
        name: "LeavingRegist",
        params: {
          studentId:item.loginId,
          studentName:item.name,
          parentMailAuthFlg:item.mailAddressAuthFlgParent,
          beforeName:"LeavingConfirm",
          param_yyyymmdd: this.yyyymmdd,
        }
      });
    },
    // 早退確定
    async onClickApprovalItem(item) {
      // ローディング開始
      this.$root.loading = true;
      this.approvalStudentId = item.loginId;
      this.approvalStudentNm = item.name;
      this.leavingApprovalDialog = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      const data = {
        funcName: "loadLeavingApproval",
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId
      };
      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        this.leavingReason = res.data.leavingReason;
        this.leavingTime = res.data.leavingTime;

        this.selectMain = [{code: "", name: "", orderNo: "", yobi1: ""}].concat(res.data.selectMain);
        this.selectSub = res.data.selectSub;
        this.selectedMain = res.data.approvalKbn.substring(0, 2);
        if(res.data.approvalKbn != ""){
          this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
        }

        this.approvalKbn = res.data.approvalKbn;
        this.approvalText = res.data.approvalText;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // 早退設定保存
    async sotaiApprovalSave() {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var data = {
        funcName: "saveLeavingApproval",
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId,
        approvalKbn: this.approvalKbn,
        approvalText: this.approvalText,
      };
      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        console.log(res.data);
        alert("登録しました");

        // reloadせずにapprovalFlgの画面更新
        this.members.forEach((doc) => {
          if (doc.loginId === this.approvalStudentId) {
            doc.approvalFlg = res.data.approvalFlg;
          }
        });
      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });

      this.leavingApprovalDialog = false;
    },
    async changeMain() {
      console.log('changeMain');
      this.approvalKbn = "";
      this.approvalText = "";
      this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
    },
    async changeSub() {
      console.log('changeSub');
      if(!this.approvalKbn.endsWith('99')){
        this.approvalText = "";
      }
    },
    // ページ再読込
    async reload() {
      this.members = [];

      var param = {
        funcName: "loadLeavingConfirm",
        yyyymmdd: this.yyyymmdd
      }
      var comStatusNames = [
        { label: "未連絡", statusKbn: 0 },
        { label: "保護者からの連絡", statusKbn: 1 },
        { label: "連絡取れず", statusKbn: 2 },
        { label: "連絡済(お迎えあり)", statusKbn: 3 },
        { label: "連絡済(お迎えなし)", statusKbn: 4 },
      ];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(param).then((leavings) => {
        var ret = [];
        leavings.data.forEach((leaving) => {
          var comStatusName = comStatusNames.filter(x => x.statusKbn === leaving.comStatusKbn)[0];
          var row = {
            mailAddressAuthFlg: leaving.mailAddressAuthFlg,
            name : leaving.name,
            mailAddressAuthFlgParent: leaving.mailAddressAuthFlgParent,
            parentName : leaving.parentName,
            filterItem : leaving.filterItem,
            kateiNm: leaving.kateiNm,
            buNm: leaving.buNm,
            kaNm: leaving.kaNm,
            gakunen: leaving.gakunen,
            gakkyu: leaving.gakkyu,
            syussekiNo: leaving.syussekiNo,
            cancelFlg: leaving.cancelFlg,
            leavingReason: leaving.leavingReason,
            comStatusKbn: leaving.comStatusKbn,
            comStatusName: comStatusName.label,
            leavingTime: leaving.leavingTime,
            unreadFlg: (leaving.unreadFlg == true) ? "未読" : "既読",
            approvalFlg: (leaving.approvalFlg == true) ? true : false,
            arrivalStatusFlg: (leaving.arrivalStatusFlg == true) ? true : false,
            arrivalTime: (leaving.arrivalStatusFlg == true) ? leaving.arrivalTime : "",
            updateId: leaving.updateId,
            updateUserName: leaving.updateUserName,
            updateUserKbn: leaving.updateUserKbn,

            loginId: leaving.loginId,
            pastLockFlg: leaving.pastLockFlg,
            gatePassageTime: leaving.gatePassageTime,
            gatePassageFlg: (leaving.gatePassageTime) ? true : false,
          };
          ret.push(row);
        });
        this.members = ret;
        var nowDate = format(new Date(), 'yyyy-MM-dd', { locale: ja });
        this.nowDateFlg = (nowDate == this.yyyymmdd) ? false : true;
        this.isListLoding = false;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // 通用門通過時刻確認
    async onClickGatePassageItem(item) {
      var param = {
        funcName: "saveGatePassagTime",
        studentId: item.loginId,
      }

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data){
          item.gatePassageFlg = true;
          item.gatePassageTime = ret.data;
        }
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    rowStyle: function (item) {
      return item.cancelFlg == false ? 'active' : 'not-active'
    },
    updHeight(){
      this.tableHeight = parseInt(window.innerHeight * 0.6);
    },
  },
};
</script>

<style>
.not-active {
  background-color: rgb(182, 182, 182)
}
.active {
  background-color: rgb(255, 255, 255)
}
</style>