import BasePlugin from '@uppy/core/lib/BasePlugin.js'
import { ref, uploadBytesResumable } from "firebase/storage";

export default class FirebaseCloudStorage extends BasePlugin {
    constructor(uppy, opts) {
        super(uppy, opts);
        if (!opts.storage) {
            throw Error(
                "Please provide the root storageRef to be used as option `storageRef`. See https://firebase.google.com/docs/storage/web/upload-files"
            );
        }
        if (!opts.storagePath) {
            throw Error(
                "Please provide the root storageRef to be used as option `storageRef`. See https://firebase.google.com/docs/storage/web/upload-files"
            );
        }
        this.type = "uploader";
        this.id = "FirebaseCloudStorage";
        this.title = "Firebase Cloud Storage";
        this.storage = opts.storage;
        this.storagePath = opts.storagePath;
        this.uploadFile = this.uploadFile.bind(this);
    }

    uploadFile(fileIds) {
        return Promise.all(
            fileIds.map(id => {
                return new Promise((resolve, reject) => {
                    const file = this.uppy.getFile(id);
                    const refId = crypto.randomUUID();
                    this.uppy.setFileMeta(file.id, { refId: refId });
                    var path = this.storagePath.replace("{uuid}", refId);
                    const mountainsRef = ref(this.storage, path);
                    var fileName = 'attachment; filename="{DL_FILE_NAME}";'.replace('{DL_FILE_NAME}', file.name);
                    var encfileName = encodeURI(file.name);
                    const metaData = {
                        contentType: file.type,
                        contentDisposition: fileName + "filename*=UTF-8''" + encfileName, 
                        customMetadata: {
                            'fileName': file.name,
                        }
                    };
                    this.uppy.emit("upload-started", file);
                    const uploadTask = uploadBytesResumable(mountainsRef, file.data, metaData);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const progressInfo = {
                                uploader: this,
                                bytesUploaded: snapshot.bytesTransferred,
                                bytesTotal: snapshot.totalBytes
                            };
                            this.uppy.emit("upload-progress", file, progressInfo);
                        },
                        error => {
                            this.uppy.emit("upload-error", file, error);
                            reject(error);
                        },
                        () => {
                            const file = this.uppy.getFile(id);
                            this.uppy.emit(
                                "upload-success",
                                file,
                                uploadTask.snapshot,
                            );
                            resolve();
                        }
                    );
                });
            })
        );
    }

    install() {
        this.uppy.addUploader(this.uploadFile);
    }

    uninstall() {
        this.uppy.removeUploader(this.uploadFile);
    }
}