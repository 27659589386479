<template>
  <v-container fluid>
    <v-alert
      v-if="!mailAddressAuthFlg"
      dense
      border="left"
      type="info"
      dismissible
    >
      右上の設定をクリックして、メールアドレスの登録をお願いいたします。
    </v-alert>
    <v-row dense>
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <!-- 検索用のテキスト入力 -->
        <v-autocomplete
          v-model="groupFilterValue"
          label="グループ指定"
          placeholder="グループ指定"
          :items="groupNames"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" sm="2" md="2" lg="2" xl="2">
        <v-switch 
          v-model="viewUnreadOnly"
          dense
          class="mt-1"
          label="未読のみ"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="6" sm="2" md="2" lg="2" xl="2">
        <v-switch 
          v-model="viewFavoriteOnly"
          dense
          class="mt-1"
          label="お気に入りのみ"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-tabs fixed-tabs v-model="tab">
          <v-tab href="#contact">
            学校からの連絡
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 0 && unreadContactCount <= 99">{{ unreadContactCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 99">99+</v-btn>
          </v-tab>
          <v-tab href="#survey">
            アンケート
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 0 && unreadSurveysCount <= 99">{{ unreadSurveysCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 99">99+</v-btn>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="contact">
            <v-data-table
              :headers="headers"
              :items="contacts"
              :items-per-page.sync="parPageContact"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              dense
              disable-sort
              :item-class="rowStyleContact"
              :loading="isContactsLoding" loading-text="読込中"
              ref="contact"
            >
              <!-- 投稿日と投稿者 -->
              <template #[`item.openDateAndPostUser`]="{ item }">
                <v-row dense><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row dense><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <template #[`item.target`]="{ item }">
                <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                <div v-if="item.targetSelectFlg">【限定】</div>
              </template>
              <template #[`item.commentFlg`]="{ item }">
                <v-icon class="ml-2" v-if="item.commentFlg">mdi-comment-text-outline</v-icon>
                <v-icon v-if="item.unreadCommentCount > 0" large color="red">mdi-new-box</v-icon>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon @click="changeFavoriteContacts(item)" v-if="item.favoriteFlg"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon @click="changeFavoriteContacts(item)" v-if="!item.favoriteFlg"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <template #[`item.unreadFlg`]="{ item }">
                <v-btn color="accent" class="black--text" large @click="onClickContactDetails(item)">詳細</v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="survey">
            <v-data-table
              :headers="surveyheaders"
              :items="surveylist"
              :items-per-page.sync="parPageSurvey"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              dense
              disable-sort
              :item-class="rowStyleSurvey"
              :loading="isSurveysLoding" loading-text="読込中"
              ref="survey"
            >
              <!-- 投稿日と投稿者 -->
              <template #[`item.openDateAndPostUser`]="{ item }">
                <v-row dense><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row dense><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <template #[`item.target`]="{ item }">
                <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                <div v-if="item.targetSelectFlg">【限定】</div>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon @click="changeFavoriteSurveys(item)" v-if="item.favoriteFlg"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon @click="changeFavoriteSurveys(item)" v-if="!item.favoriteFlg"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <template #[`item.unreadFlg`]="{ item }">
                <v-btn color="accent" class="black--text" large @click="onClickSurveyDetails(item)">回答入力</v-btn>
              </template>
              <template #[`item.clear`]="{ item }">
                <v-btn color="grey" class="black--text" large @click="onClickClearSurveyInput(item)">クリア</v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-btn fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
      <v-icon color="white">mdi-refresh</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
export default {
  data() {
    return {
      tab: 'contact',
      groupFilterValue: "",
      viewUnreadOnly: false,
      viewFavoriteOnly: false,
      groupNames : [],
      headers: [
        { text: "タイトル", value: "title" },
        { text: "グループ名", value: "groupName", filter: this.groupNameFilter },
        { text: "対象", value: "target" },
        { text: "更新日時/更新者", value: "openDateAndPostUser" },
        { text: "コメント", value: "commentFlg" },
        { text: "お気に入り", value: "favoriteFlg", filter: this.favoriteFilter },
        { text: "既読/未読", value: "unreadState" },
        { text:"" ,value:"unreadFlg", filter: this.unreadFilter}
      ],
      contacts: [],
      surveyheaders : [
        { text: "タイトル", value: "title" },  
        { text: "グループ名", value: "groupName", filter: this.groupNameFilter },
        { text: "対象", value: "target" },
        { text: "更新日時/更新者", value: "openDateAndPostUser" },
        { text: "お気に入り", value: "favoriteFlg", filter: this.favoriteFilter },
        { text: "既読/未読", value: "unreadState" },
        { text: "入力状況", value: "inputState" },
        { text:"" ,value:"unreadFlg", filter: this.unreadFilter},
        { text:"" ,value:"clear"}
      ],
      surveylist : [],
      isContactsLoding: false,
      isSurveysLoding: false,
      mailAddressAuthFlg: true,
      parPageContact: 30,
      parPageSurvey: 30,
      unreadContactCount: 0,
      unreadSurveysCount: 0,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.tab = this.$store.state.viewConditionTabSelect;
    this.groupFilterValue = this.$store.state.searchConditionGroupName;
    this.viewUnreadOnly = this.$store.state.searchConditionUnread;
    this.viewFavoriteOnly = this.$store.state.searchConditionFavorite;
    this.parPageContact = this.$store.state.parPageStudentTopPageContact;
    this.parPageSurvey = this.$store.state.parPageStudentTopPageSurvey;
    await this.reload();
  },
  watch: {
    tab: function(newVal) {this.$store.dispatch('setViewConditionTabSelect', newVal)},
    groupFilterValue: function(newVal) {this.$store.dispatch('setSearchConditionGroupName', newVal)},
    viewUnreadOnly: function(newVal) {this.$store.dispatch('setSearchConditionUnread', newVal)},
    viewFavoriteOnly: function(newVal) {this.$store.dispatch('setSearchConditionFavorite', newVal)},
    parPageContact: function(newVal) {this.$store.dispatch('setParPageStudentTopPageContact', newVal)},
    parPageSurvey: function(newVal) {this.$store.dispatch('setParPageStudentTopPageSurvey', newVal)},
  },
  methods: {
    // グループ名フィルタ
    groupNameFilter(value) {
      if (!this.groupFilterValue) {
        return true;
      }
      return value.toString().includes(this.groupFilterValue);
    },
    // 未読フィルタ
    unreadFilter(value) {
      if (!this.viewUnreadOnly) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter(value) {
      if (!this.viewFavoriteOnly) {
        return true;
      }
      return value;
    },
    // 連絡詳細
    onClickContactDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.contact.$children[0].filteredItems;
      // 連絡IDのみ抽出
      var contactListBuff = Enumerable.from(items).select(x => { return x.messageId }).toArray();
      var contactListIndex = contactListBuff.indexOf(item.messageId);

      this.$router.push({
        name: "ContactDetails",
        params: {
          messageId: item.messageId,
          contactListBuff: contactListBuff,
          contactListIndex: contactListIndex,
        }
      });
    },
    // アンケート回答
    onClickSurveyDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.survey.$children[0].filteredItems;
      // 調査IDのみ抽出
      var surveyListBuff = Enumerable.from(items).select(x => { return x.responseId }).toArray();
      var surveyListIndex = surveyListBuff.indexOf(item.responseId);

      this.$router.push({
        name: "SurveyDetails",
        params: {
          responseId : item.responseId,
          surveyListBuff: surveyListBuff,
          surveyListIndex: surveyListIndex,
        } 
      });
    },
    // ページ再読込
    async reload() {
      var unreadContactCount = 0;
      var unreadSurveysCount = 0;
      var temp_groupNames = new Set();
      // 学校からの連絡取得
      this.contacts = [];
      this.surveylist = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isContactsLoding = true;
      this.isSurveysLoding = true;
      await schoolAidHomeLogined({funcName:"loadTopPage"}).then((contacts) => {
        var ret = [];
        contacts.data.list.forEach((contact) => {
          temp_groupNames.add(contact.groupName);
          var row = {
            openDate: contact.openDate,
            postUser: contact.postUser,
            groupName: contact.groupName,
            studentFlg: contact.studentFlg,
            parentFlg: contact.parentFlg,
            teacherFlg: contact.teacherFlg,
            title: contact.title,
            messageId: contact.messageId,
            commentFlg: contact.commentFlg,
            unreadFlg: (contact.unreadFlg)?contact.unreadFlg:(contact.unreadCommentCount>0),
            unreadCommentCount: contact.unreadCommentCount,
            favoriteFlg: contact.favoriteFlg,
            targetSelectFlg: contact.targetSelectFlg,
          };
          if(row.unreadFlg){
            row.unreadState = "未読";
            unreadContactCount++;
          } else {
            row.unreadState = "既読";
          }
          ret.push(row);
        });
        this.unreadContactCount = unreadContactCount;
        this.contacts = ret;
        this.mailAddressAuthFlg = contacts.data.mailAddressAuthFlg;
        this.isContactsLoding = false;
      });
      await schoolAidHomeLogined({funcName:"loadTopPageSurvey"}).then((snapshot) => {
        var ret = [];
        snapshot.data.forEach((survey) => {
          temp_groupNames.add(survey.groupName);
          var row = {
            openDate: survey.openDate,
            postUser: survey.postUser,
            groupName: survey.groupName,
            studentFlg: survey.studentFlg,
            parentFlg: survey.parentFlg,
            teacherFlg: survey.teacherFlg,
            title: survey.title,
            responseId: survey.responseId,
            unreadFlg: survey.unreadFlg,
            inputFlg: survey.inputFlg,
            favoriteFlg: survey.favoriteFlg,
            targetSelectFlg: survey.targetSelectFlg,
          };
          if(survey.unreadFlg){
            row.unreadState = "未読";
            unreadSurveysCount++;
          } else {
            row.unreadState = "既読";
          }
          if(survey.inputFlg){
            row.inputState = "入力済";
          } else {
            row.inputState = "未入力";
          }
          ret.push(row);
        });
        this.unreadSurveysCount = unreadSurveysCount;
        this.surveylist = ret;
        this.isSurveysLoding = false;
      });
      this.groupNames = Array.from(temp_groupNames);
    },
    // アンケート入力をクリア
    async onClickClearSurveyInput(item) {
      var result = confirm("アンケートの入力内容を消去してよろしいですか？");
      if (!result) {
        return;
      }

      var param = {
        funcName:"clearSurveyInput",
        responseId : item.responseId,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      await this.reload();
    },
    async changeFavoriteContacts(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteContacts",
        contactId: item.messageId,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      item.favoriteFlg = !item.favoriteFlg;
    },
    async changeFavoriteSurveys(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteSurveys",
        surveyId: item.responseId,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      item.favoriteFlg = !item.favoriteFlg;
    },
    rowStyleContact: function (item) {
      return item.unreadFlg == true ? 'not-yet-style' : 'do-style'
    },
    rowStyleSurvey: function (item) {
      return item.inputFlg == true ? 'do-style' : 'not-yet-style'
    },
  },  
};
</script>

<style>
.do-style {
  background-color: rgb(182, 182, 182)
}
.not-yet-style {
  background-color: rgb(255, 255, 255)
}
</style>