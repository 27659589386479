<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p >コース選択</p>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider
                name="大学区分(現時点で志望する大学)"
                rules="required"
                v-slot="{ errors }"
                :skipIfEmpty="false"
              >
                <v-select
                  label="大学区分(現時点で志望する大学)"
                  item-text="name"
                  item-value="code"
                  @change="changeDaigakuCourse"
                  v-model="daigakuKbn"
                  :items="daigakuMst"
                  outlined
                  hide-details="auto"
                  :disabled="lockFlg"
                ></v-select>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider
                name="志望する文理コース"
                rules="required"
                v-slot="{ errors }"
                :skipIfEmpty="false"
              >
                <v-select
                  label="志望する文理コース"
                  item-text="name"
                  item-value="code"
                  @change="changeDaigakuCourse"
                  v-model="sentakuCourseCd"
                  :items="courseMst"
                  outlined
                  hide-details="auto"
                  :disabled="lockFlg"
                ></v-select>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              選択科目
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider
                name="選択科目"
                rules="required"
                v-slot="{ errors }"
                :skipIfEmpty="false"
              >
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="kamokuMst"
                  item-key="code"
                  no-data-text="大学区分とコースを選択してください。"
                  show-select
                  single-select
                  dense
                  disable-sort
                  hide-default-footer
                  :items-per-page="-1"
                >
                </v-data-table>
              <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              【新高３貫６の方へ】<br>
                音楽・美術それぞれにおいて受け入れ人数に制限があるため、希望が通らない場合があります。<br>
                進路に関わる理由（受験に必要等）で、どちらの科目を優先的に選択したい場合は、<br>
                その旨を記載してください。<br>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="備考" rules="max:200" v-slot="{ errors }">
                <v-textarea
                  v-model="biko"
                  label="備考"
                  placeholder=""
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="lockFlg"
                ></v-textarea>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="accent" class="black--text" @click="saveCourseSelect" :disabled="invalid || lockFlg" width="100%">
            登録する
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
export default {
  data() {
    return {
      // 大学区分(選択値)
      daigakuKbn: "",
      // 志望する文理コース
      sentakuCourseCd: "",
      // 選択科目
      selected: [],
      // 備考
      biko: "",
      // 大学区分マスタ
      daigakuMst: [],
      // 選択コースマスタ
      courseMst: [],
      // 選択科目マスタ(ベース)
      kamokuMstBase: [],
      // 選択科目マスタ
      kamokuMst: [],
      lockFlg: false,

      // 選択科目テーブルヘッダ
      headers: [
        { text: "(数学)", value: "yobi1"},
        { text: "(理科)", value: "yobi2"},
        { text: "(地公)", value: "yobi3" },
        { text: "(芸術)", value: "yobi4" },
      ],
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.$root.loading = true;
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

    const data = {
      funcName: "loadCourseSelect"
    };

    await schoolAidHomeLogined(data).then((res) => {
      this.daigakuMst = res.data.daigakuMst;
      this.courseMst = res.data.courseMst;
      this.kamokuMstBase = res.data.kamokuMst;

      this.daigakuKbn = res.data.daigakuKbn;
      this.sentakuCourseCd = res.data.sentakuCourseCd;
      this.changeDaigakuCourse();
      this.selected = Enumerable.from(this.kamokuMstBase).where(i => i.code == res.data.sentakuKamokuCd).toArray();
      this.biko = res.data.biko;

      this.lockFlg = res.data.lockFlg;
    }).catch((e) =>{
      this.error = e.message;
      this.$root.loading = false;
    });

    // ローディング終了
    this.$root.loading = false;
  },
  methods: {
    // コース選択保存
    async saveCourseSelect() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveCourseSelect",
        daigakuKbn : this.daigakuKbn,
        sentakuCourseCd : this.sentakuCourseCd,
        sentakuKamokuCd : this.selected[0].code,
        biko : this.biko,
      };

      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        alert("登録しました。");
      }).catch((e) =>{
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // コース変更時
    changeDaigakuCourse() {
      // 選択科目リストの切り替え
      this.kamokuMst = Enumerable.from(this.kamokuMstBase).where(i => 
        i.code.substring(4, 9) == (
          this.daigakuKbn
          + "-"
          + this.sentakuCourseCd
          )).toArray();
      // 現在選択値の変更
      this.selected = [];
    },
  },
};
</script>