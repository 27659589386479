<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center"
        ><v-card class="mx-auto text-center" color="blue-grey" dark
          >アンケート回答状況一覧</v-card
        ></v-col
      >
    </v-row>
    <v-row>
      <v-tabs>
        <v-tab href="#students">生徒</v-tab>
        <v-tab href="#parents">保護者</v-tab>
        <v-tab href="#teachers">教職員</v-tab>

        <v-tab-item value="students">
          <v-col cols="12">
            <v-data-table
              :headers="studentHed"
              :items="students"
              :items-per-page="-1"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              :item-class="rowStyle"
              :loading="isListLoding" loading-text="読込中">
              <template #[`item.inputFlg`]="{ item }">
                <v-row dense v-if="item.inputFlg"><v-col cols="12" justify="center">済</v-col></v-row>
                <v-row dense v-if="!item.inputFlg"><v-col cols="12" justify="center">未</v-col></v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-tab-item>
        <v-tab-item value="parents">
          <v-col cols="12">
            <v-data-table
              :headers="parentsHed"
              :items="parents"
              :items-per-page="-1"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              :item-class="rowStyle"
              :loading="isListLoding" loading-text="読込中">
              <template #[`item.inputFlg`]="{ item }">
                <v-row dense v-if="item.inputFlg"><v-col cols="12" justify="center">済</v-col></v-row>
                <v-row dense v-if="!item.inputFlg"><v-col cols="12" justify="center">未</v-col></v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-tab-item>
        <v-tab-item value="teachers">
          <v-col cols="12">
            <v-data-table
              :headers="teacherHed"
              :items="teachers"
              :items-per-page="-1"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              :item-class="rowStyle"
              :loading="isListLoding" loading-text="読込中">
              <template #[`item.inputFlg`]="{ item }">
                <v-row dense v-if="item.inputFlg"><v-col cols="12" justify="center">済</v-col></v-row>
                <v-row dense v-if="!item.inputFlg"><v-col cols="12" justify="center">未</v-col></v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-tab-item>
      </v-tabs>
    </v-row>
    <v-row>
      <v-col cols="10">
      </v-col>
      <v-col cols="2">
        <v-btn color="grey" @click="toContactList" class="white--text">戻る</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['id'],
  data() {
    return {
      studentHed: [
        { text: "氏名", value: "userNm", align: "start"},
        { text: "課程", value: "kateiNm" },
        { text: "部", value: "buNm" },
        { text: "科", value: "kaNm" },
        { text: "学年", value: "gakunen" },
        { text: "学級", value: "gakkyu" },
        { text: "出席番号", value: "syussekiNo" },
        { text: "状況", value: "inputFlg" },
      ],
      students: [],
      parentsHed: [
        { text: "生徒氏名", value: "studentNm", align: "start"},
        { text: "課程", value: "kateiNm" },
        { text: "部", value: "buNm" },
        { text: "科", value: "kaNm" },
        { text: "学年", value: "gakunen" },
        { text: "学級", value: "gakkyu" },
        { text: "出席番号", value: "syussekiNo" },
        { text: "保護者氏名", value: "userNm"},
        { text: "状況", value: "inputFlg" },
      ],
      parents: [],
      teacherHed: [
        { text: "氏名", value: "userNm", align: "start"},
        { text: "状況", value: "inputFlg" },
      ],
      teachers: [],
      isListLoding: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {

    const data = {
      funcName: "loadAnswerStatusSurvey",
      surveyId: this.id
    };
    
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    this.isListLoding = true;
    await schoolAidHomeLogined(data).then((ret) => {
      console.log(ret);
      this.students = ret.data.students;
      this.parents = ret.data.parents;
      this.teachers = ret.data.teachers;
      this.isListLoding = false;
    });
  },
  methods: {
    toContactList(){
      this.$router.push({
        name: "SurveyList",
      });
    },
    rowStyle: function (item) {
      return item.inputFlg == true ? 'do-style' : 'not-yet-style'
    },
  },
};
</script>

<style>
.do-style {
  background-color: rgb(182, 182, 182)
}
.not-yet-style {
  background-color: rgb(255, 255, 255)
}
</style>