<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          グループ選択
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="groups"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isGroupsLoding" loading-text="読込中"
          >
          <template #[`item.favoriteFlg`]="{ item }">
            <v-btn icon @click="changeFavoriteGroup(item)" v-if="item.favoriteFlg"><v-icon color="orange">mdi-star</v-icon></v-btn>
            <v-btn icon @click="changeFavoriteGroup(item)" v-if="!item.favoriteFlg"><v-icon>mdi-star-outline</v-icon></v-btn>
          </template>
          <template #[`item.toGroupMemberList`]="{ item }">
            <v-btn  color="accent" class="black--text" large  @click="onClicktoGroupMemberList(item)">所属者一覧</v-btn>
          </template>
          <template #[`item.toContactRegist`]="{ item }">
            <v-btn  color="accent" class="black--text" large  @click="onClicktoContactRegist(item)">連絡登録</v-btn>
          </template>
          <template #[`item.toSurveyRegist`]="{ item }">
            <v-btn  color="accent" class="black--text" large  @click="onClicktoSurveyRegist(item)">アンケート登録</v-btn>
          </template>
          <template #[`item.toTargetSelect`]="{ item }">
            <v-btn  color="accent" class="black--text" large  @click="onClicktoTargetSelect(item)">対象選択</v-btn>
          </template>
          <template #[`item.toUserList`]="{ item }">
            <v-btn  color="accent" class="black--text" large :disabled="!item.canViewFlg" @click="onClicktoUserList(item)">生徒一覧</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
export default {
  data() {
    return {
      headers: [
        { text: "グループ名", value: "groupName" },
        { text: "お気に入り", value: "favoriteFlg" },
        { text:"" ,value:"toGroupMemberList"},
        { text:"" ,value:"toContactRegist"},
        { text:"" ,value:"toSurveyRegist"},
        { text:"" ,value:"toTargetSelect"},
        { text:"" ,value:"toUserList"},
      ],
      groups: [],
      isGroupsLoding: false,
      perPage: 30,
    };
  },
  watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageGroupSelect', newVal);
    },
  },
  // 初期表示時データ読み込み
  async created() {
    this.perPage = this.$store.state.parPageGroupSelect;  
    await this.reload();
  },
  methods: {
    // ページ再読込
    async reload() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isGroupsLoding = true;
      schoolAidHomeLogined({funcName:"loadGroupSelect"}).then((groups) => {
        var ret = [];
        groups.data.forEach((group) => {
          var row = {
            groupId : group.groupId,
            favoriteFlg: group.favoriteFlg,
            groupName: group.groupName,
            canViewFlg: group.canViewFlg,
          };
          ret.push(row);
        });
        this.groups = ret;
        this.isGroupsLoding = false;
      });
    },
    async changeFavoriteGroup(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteGroup",
        groupId: item.groupId,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      this.reload();
    },
    // 連絡登録
    onClicktoContactRegist(item) {
      console.log(item.groupName, item.groupId);
      this.$router.push({
        name: "ContactRegist",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
        }
      });
    },
    // 所属者一覧
    onClicktoGroupMemberList(item) {
      this.$router.push({
        name: "GroupMemberList",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
          beforeName:"GroupSelect"
        }
      });
    },
    // アンケート登録
    onClicktoSurveyRegist(item) {
      console.log(item.groupName, item.groupId);
      this.$router.push({
        name: "SurveyRegist",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
        }
      });
    },
    
    onClicktoTargetSelect(item) {
      console.log(item.groupName, item.groupId);
      this.$router.push({
        name: "TargetSelect",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
        }
      });
    },
    // 生徒一覧グループ選択
    onClicktoUserList(item) {
      console.log(item.groupName, item.groupId);
      var yyyymmdd = format(new Date(), 'yyyy-MM-dd', { locale: ja });
      this.$router.push({
        name: "UserList",
        params: {
          groupName:item.groupName,
          groupId:item.groupId,
          param_yyyymmdd: yyyymmdd,
        }
      });
    },
  },
};
</script>