<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="374"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p >早退返信</p>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <v-text-field
                  v-model="leavingReason"
                  label="早退事由"
                  placeholder="早退事由"
                  outlined
                  dense
                  disabled
                  hide-details="auto"
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="leavingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider name="下校開始予定時刻" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model="leavingTime"
                    label="下校開始予定時刻"
                    placeholder="下校開始予定時刻"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                    <p class="error">{{ errors[0] }}</p>
                  </ValidationProvider>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="leavingTime"
                  :allowed-hours="allowedHours"
                  :allowed-minutes="allowedStep"
                  full-width
                  @click:minute="$refs.menu.save(leavingTime)"
                  ></v-time-picker>
                </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="コメント" rules="max:150" v-slot="{ errors }">
                <v-text-field
                  v-model="comment"
                  label="コメント"
                  placeholder="コメント"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="accent" class="black--text" @click="sotaiSave()" :disabled="invalid" width="100%">
            返信する
          </v-btn>
        </v-card-actions>
        <v-card-text class="d-flex justify-left pt-0 pb-0" white-space="pre" v-if="gatePassageTime">
            <div>早退時刻：{{ gatePassageTime }} に通用門を通過しました</div>
        </v-card-text>
        <v-card
            class="d-flex justify-center flex-column mx-auto my-0 flat"
            width="1200"
            color="#fff"
          >
            <v-list>
              <v-subheader>【履歴】</v-subheader>
              <v-list-item-group  v-if="history.length">
                <v-list-item v-for="row in history" :key="row.postDateTime">
                  <v-list-item-content>
                    <v-list-item-subtitle>更新日時：{{ row.postDate }}</v-list-item-subtitle>
                    <v-list-item-subtitle>更新者：{{ row.userNm }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="wrap-text" v-if="row.leavingReason">早退事由：{{ row.leavingReason }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="wrap-text" v-if="row.leavingReasonEtc">早退事由(補足)：{{ row.leavingReasonEtc }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="wrap-text" v-if="row.leavingTime">下校開始予定時刻：{{ row.leavingTime }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="wrap-text" v-if="row.comment">コメント：{{ row.comment }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <v-list-item-group  v-if="!history.length" color="primary">
                <v-list-item>履歴はありません</v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card> 
      </v-card>
    </ValidationObserver>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['id'],
  data() {
    return {
      leavingReason: "",
      leavingTime: "",
      comment: "",
      history: [],
      isLeavingText: false,
      gatePassageTime: "",

      menu2: false,
      modal2: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // ローディング開始
    this.$root.loading = true;

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");

    const data = {
      funcName: "loadLeavingReply",
      studentId: this.id
    };

    await schoolAidHomeLogined(data).then((res) => {
      this.leavingReason = res.data.leavingReason;
      this.leavingTime = res.data.leavingTime;
      this.history = res.data.history;
      this.gatePassageTime = res.data.gatePassageTime;
    }).catch((e) => {
      console.log(e.message);
      this.$root.loading = false;
    });

    // ローディング終了
    this.$root.loading = false;
  },
  methods: {
    // タイムピッカーの入力制限設定
    allowedHours: v => 5 < v && v < 24,
    allowedStep: m => m % 10 === 0,
    // 早退設定保存
    async sotaiSave() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";

      // 呼び出し先のfunction変更
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveLeavingReply",
        studentId: this.id,  
        leavingTime: this.leavingTime,
        comment: this.comment,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        alert("返信しました");
      }).catch((e) => {
        console.log(e.message);
        this.$root.loading = false;
      });
      this.$router.push({
        name: "GuardianTopPage"
      });
    },
  },
};
</script>

<style scoped>
.wrap-text{
  white-space: normal;
}
</style>