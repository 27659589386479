<template>
  <v-container fluid>
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="374"
      color="#fff"
    >
      <img :src="require('@/assets/logo.svg')" alt="Logo" class="logo" />
      <v-card-title class="d-flex justify-center pa-0 mt-6"
        >メールアドレス確認</v-card-title
      >
      <v-card-text class="d-flex justify-center flex-column">
        <div class="text-center" v-show="this.isProcessing">
          メールアドレスを確認しています。
        </div>
        <div class="text-center" v-show="this.isSuccess">
          <br>
          メールアドレスを確認しました。<br>
          <br>
          以後、システムから未読連絡について<br>
          リマインドのメールを送信します。<br>
        </div>
        <div class="text-center" v-show="this.isFailure">
          <br>
          メールアドレスの確認に失敗しました。<br>
          <br>
          ※ご利用のメールソフトによっては、<br>
          URLが途中で改行され、<br>
          クリックしても正しく画面が<br>
          表示されない場合があります。<br>
          その場合は、URLを全てコピーして、<br>
          ブラウザのURL入力欄に<br>
          貼り付けて画面を表示させてください。<br>
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <a style="color:#000;" href="#" @click.prevent.stop="toLoginPage()">ログイン画面へ</a>
      </v-card-text>
    </v-card>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  props: ['collisionId'],
  data() {
    return {
      isProcessing: true,
      isSuccess: false,
      isFailure: false
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // ローディング開始
    this.$root.loading = true;

    var data = {
      funcName: "emailAuthentication",
      collisionId: this.collisionId,
    }
    // メールアドレス認証
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");
    await schoolAidHomePublic(data).then((res) => {
      if (res.data == "success") {
        this.isProcessing = false,
        this.isSuccess = true;
        this.isFailure = false;
      } else {
        this.isProcessing = false,
        this.isSuccess = false;
        this.isFailure = true;
      }
    }).catch(() => {
      this.isProcessing = false,
      this.isSuccess = false;
      this.isFailure = true;
      this.$root.loading = false;
    });

    // ローディング終了
    this.$root.loading = false;
  },
  methods: {
    // ログイン画面へ
    toLoginPage() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
.logo {
  display: block;
  position: relative;
  overflow: hidden;
}
</style>